import React from 'react'
import { Spinner } from 'react-bootstrap'

import styles from './SaveButton.scss'

export default class SaveButton extends React.PureComponent {
  render() {
    const { save, saving, error } = this.props
    return saving ?
      <Spinner animation='border' variant='primary' /> :
      <div title={error ? 'Could not save, please try again' : 'Save'} className={styles.saveButton} onClick={save} >
        {error && <div className={styles.error} />}
      </div>
  }
}
