import { put, takeEvery, call } from 'redux-saga/effects'

import { FETCH_STATISTICS, setStatistics } from "./statisticsActions";
import config from '../../utils/config'
import fetchHelper from '../../utils/fetchHelper'
import progressSaga from '../progress/progressSaga'


export const sagas = {
  [FETCH_STATISTICS]: progressSaga(() => [FETCH_STATISTICS],
    function* (action) {
      const { statisticsUrl } = config
      const { payload: { productKey, accountId } } = action      
      const updateStatistics = productKey ? yield call(fetchHelper, `${statisticsUrl}/statistics/update?productKey=${productKey}`,
        {
          method: 'GET',
          credentials: 'include',
          headers: { 'Access-Control-Allow-Origin': window.location.origin }
        }) : []
      
      const loginStatistics = accountId ? yield call(fetchHelper, `${statisticsUrl}/statistics/login?accountId=${accountId}`,
        {
          method: 'GET',
          credentials: 'include',
          headers: { 'Access-Control-Allow-Origin': window.location.origin }
        }) : []

      yield put(setStatistics(accountId, { update: updateStatistics, login: loginStatistics }))
    }
  )
}

export default function* statisticsSaga() {
  yield takeEvery(FETCH_STATISTICS, sagas[FETCH_STATISTICS])
}
