const prefix = 'auth'
export const LOGIN_REQUEST = `${prefix}/login_request`
export const LOGIN_RESPONSE = `${prefix}/login_response`
export const LOGOUT = `${prefix}/logout`

export function loginRequest(username, password, history) {
  return {
    type: LOGIN_REQUEST,
    payload: {
      username,
      password,
      history
    }
  }
}

export function loginResponse(username, roles) {
  return {
    type: LOGIN_RESPONSE,
    payload: {
      username,
      roles
    }
  }
}

export function logout() {
  return {
    type: LOGOUT,
    payload: {}
  }
}

