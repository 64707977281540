import { put } from 'redux-saga/effects'
import { progressError } from '../../utils/error'
import { pending, error, done, clear } from './progressActions'

export default function (getProgressPath, saga) {
  return function* (...sagaArgs) {
    const action = sagaArgs[sagaArgs.length - 1]
    const progressPath = getProgressPath(action)
    yield put(clear(...progressPath))
    yield put(pending(true, ...progressPath))
    try {
      yield saga(...sagaArgs)
    } catch (e) {
      console.error(e)
      yield put(error(progressError(e), ...progressPath))
      // throw e // Need to fix conditional compiling
    } finally {
      yield put(done(...progressPath))
    }
  }
}
