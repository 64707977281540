import { take, put, takeEvery } from 'redux-saga/effects'
import { eventChannel } from 'redux-saga'
import PouchDB from 'pouchdb-browser'
import moment from 'moment'

import { error as er, done } from '../progress/progressActions'
import progressSaga from '../progress/progressSaga'
import { PUBLISH } from './publishActions'
import config from '../../utils/config'
import { DATE_FORMAT } from '../../constants'
import { putDocument } from '../documents/documentActions'

export const sagas = {
    [PUBLISH]: progressSaga((action) => [PUBLISH, action.payload.log._id],
        function* (action) {
            const { couchUrl } = config
            const { log } = action.payload
            const admin = new PouchDB(`${couchUrl}/admin`, {
                skip_setup: true,
                fetch: (url, opts) => {
                    opts.credentials = 'include'
                    return PouchDB.fetch(url, opts)
                }
            })
            const master = new PouchDB(`${couchUrl}/master`, {
                skip_setup: true,
                fetch: (url, opts) => {
                    opts.credentials = 'include'
                    return PouchDB.fetch(url, opts)
                }
            })
            const replicator = PouchDB.replicate(admin, master, { live: false })
            const replicationChannel = eventChannel((emitter) => {
                replicator.on('denied', function (err) {
                    emitter({ type: 'denied', event: err })
                }).on('error', function (err) {
                    emitter({ type: 'error', event: err })
                }).on('complete', function (info) {
                    emitter({ type: 'complete', event: info })
                })
                return () => { replicator.removeAllListeners() }
            })
            const { type, event } = yield take(replicationChannel)
            
            if (type === 'error') {                
                const { error, reason, status, message } = event
                yield put(er({ error, reason, status, message }, ...[PUBLISH, log._id]))
            }
            else {
                log.published = true
                log.publishDate = moment().format(DATE_FORMAT)                
                log.name = `${log.name} (${moment(log.publishDate).format('YYYY-MM-DD HH:mm:ss')})`                
                yield put(putDocument(log))
                yield put(done(...[PUBLISH, log._id]))
            }
        })
}

export default function* publishSaga() {
    yield takeEvery(PUBLISH, sagas[PUBLISH])
}
