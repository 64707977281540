import React from 'react'
import PropTypes from 'prop-types'
import { useDrop } from 'react-dnd'
import classNames from 'classnames'

import { DRAG_TYPE } from './TreeEditorConstants'
import styles from './TreeNodeDropTarget.scss'

const TreeNodeDropTarget = ({ handleDrop }) => {

    const [{ isHovering, name }, drop] = useDrop({
        accept: DRAG_TYPE,
        drop: (item, monitor) => {
            if (monitor.isOver({ shallow: true })) {
                handleDrop(item.id)
            }
        },
        collect: monitor => ({
            isHovering: !!monitor.isOver({ shallow: true }),
            name: monitor.getItem() ? monitor.getItem().name : ''
        })
    })

    return <div className={styles.treeNodeDropTarget} ref={drop}>
        {isHovering && <div className={styles.isHovering}>{name}</div>}
    </div>
}

export default TreeNodeDropTarget

TreeNodeDropTarget.propTypes = {
    handleDrop: PropTypes.func.isRequired
}