import React from 'react'
import { Modal, Button } from 'react-bootstrap'

import styles from './message.scss'

const Message = (props) => {
    const { onHide, title, body } = props
    return <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
    >
        <Modal.Header className={styles.header} closeButton>
            <Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>{body}</p>
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
    </Modal>
}

export default Message