import { serializeError } from 'serialize-error'

const prefix = 'error'
export const LOGIN_ERROR = `${prefix}/login`
export const PROGRESS_ERROR = `${prefix}/progress_error`
export const SYNC_ERROR = `${prefix}/sync_error`
export const TIMEOUT_ERROR = `${prefix}/timeout_error`
export const INVALID_ADMIN_CREDENTIALS_ERROR = `${prefix}/invalid_admin_credentials_error`

function error(type, message, nestedError) {
  return {
    type,
    message,
    nestedError: nestedError ? serializeError(nestedError) : null
  }
}

export function timeoutError(message, nestedError) {
  return error(TIMEOUT_ERROR, message, nestedError)
}

export function loginError(message, nestedError) {
  return error(LOGIN_ERROR, message, nestedError)
}

export function progressError(nestedError) {
  return error(PROGRESS_ERROR, 'An unhandled error occured', nestedError)
}

export function syncError(syncEvent) {
  return error(SYNC_ERROR, 'An unhandled error occured', syncEvent)
}

export function invalidAdminCredentials(nestedError) {
  return error(INVALID_ADMIN_CREDENTIALS_ERROR, 'Invalid admin credentials', syncEvent)
}