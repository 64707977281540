import React from 'react'
import { withRouter, Redirect, Route } from 'react-router-dom'
import { connect } from 'react-redux'

import { getAuth } from '../state/auth/authReducer'

const mapStateToProps = (state, props) => {
  return {
    auth: getAuth(state)
  }
}

function PrivateRoute({ component: Component, auth, ...rest }) {
  if(!auth.isAuthenticated){
    console.log(auth)    
  }
  return <Route
    {...rest}
    render={props =>
      auth.isAuthenticated ?
        <Component {...props} /> :
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location }
          }}
        />
    }
  />
}

export default withRouter(connect(mapStateToProps)(PrivateRoute))
