import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { withRouter, Route } from 'react-router-dom'
import { connect } from 'react-redux'
import { getDocuments } from '../../state/documents/documentReducer'

import AccountTable from '../accounts/AccountTable.jsx'
import AccountStatistics from './AccountStatistics.jsx'
import { FETCH_STATISTICS, fetchStatistics } from '../../state/statistics/statisticsActions.js'
import { getPending } from '../../state/progress/progressReducer.js'
import { getStatistics } from '../../state/statistics/statisticsReducer.js'

const mapDispatchToProps = (dispatch, props) => {
  return {
    push: (url) => props.history.push(url),
    fetchStats: (productKey, accountId) => dispatch(fetchStatistics(productKey, accountId))
  }
}

const getAllAccounts = getDocuments('account')
const fetchingStatistics = getPending(FETCH_STATISTICS)

const mapStateToProps = (state, props, ownProps) => {
  return {
    accounts: getAllAccounts(state),
    statistics: getStatistics(state),
    fetchingStatistics: !!fetchingStatistics(state),
    navigate: (accountId) => {
      props.history.push(`/statistics/${accountId}`)
    }
  }
}

class Statistics extends React.Component {
  constructor() {
    super()
    this.productKeyInputRef = React.createRef();
  }
  render() {
    const { accounts, statistics, fetchStats, fetchingStatistics, navigate } = this.props
    return <Row>
      <Col className='mt-5'>
        <Route exact path="/statistics/" render={() => {
          return <div>
            <div>
              <input placeholder="PRODUCT KEY" type="text" ref={this.productKeyInputRef} />
              <button className="ml-2"
                onClick={() => {
                  const productKey = this.productKeyInputRef.current.value
                  const account = accounts.find(a => a.productKey == productKey) || {}          
                  fetchStats(productKey, account._id)
                  navigate(account._id)
                }}>VIEW</button>
            </div>
            <AccountTable accounts={accounts} onClick={(account) => {
              const { productKey, _id } = account              
              fetchStats(productKey, _id)
              navigate(_id)
            }} />
          </div>
        }} />
        <Route path="/statistics/:accountId" render={({ match }) => {
          const accountId = match.params.accountId
          const accountStatistics = statistics[accountId] ? statistics[accountId] : {}
          const account = accounts.find(a => a._id == accountId) || {}          
          return <AccountStatistics
            account={account}
            statistics={accountStatistics}
            refreshing={fetchingStatistics}
            refresh={() => fetchStats(productKey, account._id)} />
        }} />
      </Col>
    </Row>
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Statistics))