import { createSelector } from 'reselect'
import cloneDeep from 'clone-deep'

import { SET_DRAFT } from '../documents/documentActions'
import { getDocuments } from '../documents/documentReducer'


const email = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const validateMaxNoEnabledUsers = (draft, error, others) => {
  if (draft.maxNoEnabledUsers && draft.maxNoEnabledUsers < 0) {
    error.maxNoEnabledUsers = 'Max No. Enabled Users must be greater than 0'
  }
  else {
    delete error.maxNoEnabledUsers
  }
}

const validateName = (draft, error, others) => {  
  if (draft.name && !email.test(draft.name)) {
    error.name = 'Name must be a valid email address'
  }
  else if (draft.name && Object.values(others).find((user) => user.name === draft.name)) {
    error.name = 'Name already taken!'
  }
  else {
    delete error.name
  }
}

const actions = {
  [SET_DRAFT](state, action) {    
    const { payload: { draft } } = action
    const { _id } = draft
    const others = Object.assign({}, state['user'])
    const document = cloneDeep(others[_id])
    delete others[_id]
    if (!document._progress) {
      document._progress = {}
    }
    const { _progress: { error = {} } } = document
    delete document._progress.error

    validateMaxNoEnabledUsers(draft, error, others)
    validateName(draft, error, others)
    
    if (Object.getOwnPropertyNames(error).length) {
      document._progress.error = error
    }
    others[_id] = document
    return Object.assign({}, state, { user: others })
  }
}

export default function (state, action) {
  if (actions[action.type]) {
    return actions[action.type](state, action)
  } else {
    return state
  }
}

export const getAccountUsers = (id) => createSelector(
  getDocuments('user'),
  (users) => {
    return users.filter((user) => user.accountId === id) || []
  }
)
