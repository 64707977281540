import React, { useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'

import debounce from 'debounce'
import classNames from 'classnames'

import reducer, { createTree, toggleNode, renameNode, removeNode, addNode, moveNode, resetTree } from './TreeState.js'
import TreeNode from './TreeNode.jsx'
import TreeNodeDropTarget from './TreeNodeDropTarget.jsx'
import styles from './TreeEditor.scss'

// this.setDraft = debounce(() => {
//     const draft = this.getDraft()
//     this.props.setDraft(draft)
//   }, 2000)

const renderTreeNode = (node, position, readonly, disabled, onClick, createData, dispatch) => {
    return <div key={`wrap-${node.id}`}>
        {!position.siblingId && <TreeNodeDropTarget key={`pre-${node.id}`}
            handleDrop={(id) => dispatch(moveNode(id, { parentId: position.parentId, siblingId: null }))} />}
        <TreeNode
            key={node.id}
            node={node}
            onClick={onClick}
            readonly={readonly}
            disabled={disabled}
            toggle={() => dispatch(toggleNode(node.id))}
            dropNode={(id) => dispatch(moveNode(id, { parentId: node.id, siblingId: position.siblingId }))}
            renameNode={(name) => dispatch(renameNode(node.id, name))}
            createNode={(name) => dispatch(addNode(createData(name), position))}
            removeNode={(node) => dispatch(removeNode(node.id))}
        >
            {
                node.expanded && node.children &&
                node.children.map((child, i) => renderTreeNode(child,
                    { parentId: node.id, siblingId: i > 0 ? node.children[i - 1].id : null }, readonly, disabled, onClick, createData, dispatch))
            }
        </TreeNode>
        <TreeNodeDropTarget key={`post-${node.id}`}
            handleDrop={(id) => dispatch(moveNode(id, { parentId: position.parentId, siblingId: node.id }))} />
    </div>
}

const TreeEditor = (props) => {
    const { readonly, disabled, tree, createData, onClick, onChange } = props    
    const [state, dispatch] = useReducer(reducer, createTree(tree));
    useEffect(() => dispatch(resetTree(createTree(props.tree))), [props.tree])
    useEffect(() => onChange(state), [state])
    return <div>
        {
            state.nodes.map((node, i) => renderTreeNode(node,
                { parentId: null, siblingId: i > 0 ? state.nodes[i - 1].id : null },
                readonly, disabled, onClick, createData, dispatch))
        }
    </div>
}

export default TreeEditor

// Nodes are assumed to be an array containing objects. By default these objects are assumed to have
// the following structure:
// * name: the name displayed in the TreeView
// * children: an array containing the nested nodes.
TreeEditor.propTypes = {
    createData: PropTypes.func.isRequired,
    tree: PropTypes.array.isRequired,
    readonly: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func
}
