import React from 'react'
import { Col, Form, ToggleButton, ButtonGroup } from 'react-bootstrap'
import classNames from 'classnames'

import CountrySelector from '../../countrySelector/CountrySelector.jsx'
import AccountSchema from '../../../../data/accounts/schema.account.json'
import styles from './License.scss'

const getDisplayName = (str) => `${str[0].toUpperCase()}${str.slice(1)}`.replace('_', ' ')

const License = (props) => {
  const { countries, draft, document, setDraft, error, readOnly } = props
  return <Form.Row className="mt-2">
    <Col>
      <Form.Group controlId="calculator">
        <Form.Label className={classNames("d-block ", styles.label)}>Calculator</Form.Label>
        <ButtonGroup toggle className="mb-2">
          {
            AccountSchema.properties.license.properties.calculator.enum.map((calculator, i) => {
              const { license: { calculator: value } } = draft
              return <ToggleButton
                disabled={readOnly}
                className={value === calculator && styles.selected}
                key={i}
                type="radio"
                variant="secondary"
                name="calculator"
                value
                checked={value === calculator}
                onChange={(e) => {
                  const newDraft = Object.assign({}, draft, { license: Object.assign({}, draft.license, { calculator }) })
                  setDraft(newDraft)
                }}
              >
                {getDisplayName(calculator)}
              </ToggleButton>
            })
          }
        </ButtonGroup>
      </Form.Group>
      <Form.Group controlId="modules" className="mb-2">
        <Form.Label className={classNames("d-block ", styles.label)}>Modules</Form.Label>
        <ButtonGroup vertical toggle className="mb-2">
          {
            AccountSchema.properties.license.properties.modules.items.enum.map((module, i) => {
              const { license: { modules } } = draft
              return <ToggleButton
              disabled={readOnly}
                className={modules.includes(module) && styles.selected}
                key={i}
                type="checkbox"
                variant="secondary"
                name="modules"
                value={module}
                checked={modules.includes(module)}
                onChange={(e) => {
                  const newModules = modules.includes(module) ?
                    [...modules.slice(0, modules.indexOf(module)), ...modules.slice(modules.indexOf(module) + 1)] :
                    [...modules, module]
                  const newDraft = Object.assign({}, draft, { license: Object.assign({}, draft.license, { modules: newModules }) })
                  setDraft(newDraft)
                }}
              >
                {getDisplayName(module)}
              </ToggleButton>
            })
          }
        </ButtonGroup>
      </Form.Group>
      <CountrySelector onChange={(countries) => {
        const newDraft = Object.assign({}, draft, { license: Object.assign({}, draft.license, { countries }) })
        setDraft(newDraft)
      }}
        allCountries={countries} selectedCountries={draft.license.countries} readOnly={readOnly}>Countries</CountrySelector>

    </Col>
  </Form.Row >
}

export default License