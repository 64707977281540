// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".src-components-documentEditor-textEditor-Separator__separator--3AHO5{width:2px;background:#BCBEC7;display:inline-block;height:24px}\n", "",{"version":3,"sources":["C:/Gitlab-Runner/builds/josiD6fY/0/developers/comtax-admin/src/components/documentEditor/textEditor/Separator.scss","C:/Gitlab-Runner/builds/josiD6fY/0/developers/comtax-admin/src/components/_base.scss"],"names":[],"mappings":"AAEA,sEACE,SAAU,CACV,kBCIkB,CDHlB,oBAAqB,CACrB,WAAY","file":"Separator.scss","sourcesContent":["@import '../../_base.scss';\r\n\r\n.separator {\r\n  width: 2px;\r\n  background: $french-gray;\r\n  display: inline-block;\r\n  height: 24px;\r\n}","// Colors\r\n$white: #fff;\r\n$alice-blue: #f0f8ff;\r\n$blue: #253652;\r\n$black: #000;\r\n$red: #e2061c;\r\n$bittersweet: #fe6666;\r\n$chathams-blue: #135c72;\r\n$french-gray:#BCBEC7;\r\n$alto: #DEDEDE;\r\n$mine-shaft: #232323;\r\n$steel-gray: #1d1b27;\r\n$zircon: #f9faff;\r\n$silver-chalice: #b1b1b1;\r\n$limeade: #5fbf00;\r\n$salem: #0c964b;\r\n$selective-yellow: #FFB100;\r\n$thunderbird: #c71d1d;\r\n$hippie-blue: #5A9AA8;\r\n$ecstasy: #FD7E14;\r\n$tenn: #CC5D00;\r\n$endeavour: #0056b3;\r\n$malibu: #5AAAFF;\r\n// Constants\r\n$scrollbar-width: 17px;\r\n$font-family: Helvetica, Arial, Sans-Serif;\r\n$font-size: 12px;\r\n\r\n// Utility functions\r\n\r\n@function sqrt($r) {\r\n  $x0: 1;\r\n  $x1: $x0;\r\n\r\n  @for $i from 1 through 10 {\r\n    $x1: $x0 - ($x0 * $x0 - abs($r)) / (2 * $x0);\r\n    $x0: $x1;\r\n  }\r\n\r\n  @return $x1;\r\n}\r\n"]}]);
// Exports
exports.locals = {
	"separator": "src-components-documentEditor-textEditor-Separator__separator--3AHO5"
};
module.exports = exports;
