import React, { useState } from 'react'
import { Form, Col, Modal, Button, ButtonToolbar, Spinner } from 'react-bootstrap'
import styles from './PutAccountDialog.scss'

const PutAccountDialog = (props) => {

  const { show,
    hide,
    putAccount,
    pendingPutAccount,
    errorPutAccount } = props
  
  const [validated, setValidated] = useState(false)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const formRef = React.useRef()
  const save = () => {
    const valid = formRef.current.checkValidity()
    if (valid) {
      putAccount(username, password)

    }
    setValidated(true)
  }

  return <Modal
    show={show}    
    backdrop="static"
    keyboard={false}
    centered
    dialogClassName={styles.dialog}
  >
    <Modal.Header>
      <Modal.Title>CONFIRM CREDENTIALS</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Form ref={formRef} noValidate validated={validated}>
        <p>Please enter your username and password.</p>
        <Form.Row>
          <Col lg={6}>
            <Form.Group controlId="userName">
              <Form.Label className={styles.label}>Username</Form.Label>
              <Form.Control
                type="text"
                required
                placeholder="Username"
                onChange={
                  ({ target: { value: username } }) => {
                    setUsername(username)
                  }}
                value={username}
              //isInvalid={errorPutAccount}
              //isValid={doneConfirmingCredentials}
              />
              <Form.Control.Feedback type="invalid">Username is required</Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="password">
              <Form.Label className={styles.label}>Password</Form.Label>
              <Form.Control
                type="password"
                required
                placeholder="password"
                onChange={
                  ({ target: { value: password } }) => {
                    setPassword(password)
                  }}
                value={password}
              //isInvalid={errorPutAccount}
              //isValid={doneConfirmingCredentials}
              />
              <Form.Control.Feedback type="invalid">Password is required</Form.Control.Feedback>
            </Form.Group>
            {errorPutAccount &&
              <strong className={styles.error}>{errorPutAccount
                && (errorPutAccount.message)}</strong>
            }
          </Col>
        </Form.Row>
      </Form>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={hide} disabled={pendingPutAccount}>
        Cancel
      </Button>
      <div className='form-group'>
        <ButtonToolbar>
          <Button variant='primary' onClick={save} disabled={pendingPutAccount}>
            {pendingPutAccount ?
              <Spinner
                as='span'
                animation='border'
                size='sm'
                role='status'
                aria-hidden='true'
              /> : 'Ok'}
          </Button>
        </ButtonToolbar>
      </div>
    </Modal.Footer>
  </Modal>
}

export default PutAccountDialog