import React, { useState, useRef, useLayoutEffect, useEffect } from 'react'
import { Table, Row, Col, Form, Button, InputGroup, FormControl } from 'react-bootstrap'
import className from 'classnames'

import { hasChanges, hasError, isPending } from '../../../utils/documentUtils'
import SaveButton from '../../buttons/SaveButton.jsx'
import DustbinButton from '../../buttons/DustbinButton.jsx'
import styles from './Users.scss'

const CreateNew = (props) => {
  const { user, putUser, putUserError, putUserPending, discardUser, setDraft } = props
  const { _draft: draft, _progress = {} } = user
  const { error = {} } = _progress

  return <Form.Row>
    <Form.Group as={Col} controlId="name">
      <Form.Control
        type="email"
        required
        placeholder="name@example.com"
        onChange={
          ({ target: { value: name } }) => {
            const newDraft = Object.assign({}, draft, { name })
            setDraft(newDraft)
          }}
        value={draft.name}
        isInvalid={error.name}
        isValid={draft.name && !error.name}
      />
      <Form.Control.Feedback type="invalid">{error.name}</Form.Control.Feedback>
    </Form.Group>
    <Col>
      {hasChanges(user) && !hasError(user) &&
        <SaveButton save={() => {
          putUser(user)
        }} saving={putUserPending} error={putUserError} />
      }
      {!putUserPending && <DustbinButton discard={() => discardUser(draft)} />}
    </Col>
  </Form.Row>
}

const Enabled = ({ user, error, readOnly, enableUser }) =>
  <Form.Check
    disabled={readOnly}
    size="lg"
    checked={user.enabled}
    onChange={
      ({ target: { checked: enabled } }) => {
        const newDraft = Object.assign({}, user._draft, { enabled })
        enableUser(Object.assign({}, user, { _draft: newDraft }))
      }}
    isInvalid={error.enabled}
  />


const Notified = ({ user, error }) =>
  <Form.Check
    disabled={true}
    size="lg"
    checked={user.notified}
    onChange={
      ({ target: { checked: enabled } }) => {
        const newDraft = Object.assign({}, user, { enabled })
        setDraft(newDraft)
      }}
    isInvalid={error.notified}
  />


const Users = (props) => {
  const { account,
    setDraft,
    createDraft,
    error,
    users,
    createUser,
    putUser,
    enableUser,
    putUserError,
    putUserPending,
    notifyUser,
    discardUser,
    readOnly } = props
  const [createNew, setCreateNew] = useState(false)
  const newUser = users.find((user) => user._id.startsWith('__temp__'))

  useLayoutEffect(() => {
    users
      .filter((user) => !user._draft)
      .forEach((user) => createDraft(user))
  })

  return <>
    <Form.Row>
      <Col lg={2}>
        <Form.Group controlId="maxNoEnabledUsers">
          <Form.Label className={styles.label}>Max No. Enabled Users</Form.Label>
          <FormControl
            formNoValidate
            type="number"
            min="0"
            required
            readOnly={createNew || readOnly}
            onChange={
              ({ target: { value: maxNoEnabledUsers } }) => {
                const newDraft = Object.assign({}, account._draft, { maxNoEnabledUsers: parseInt(maxNoEnabledUsers, 10) })
                setDraft(newDraft)
              }}
            value={account._draft.maxNoEnabledUsers}
            isInvalid={error.maxNoEnabledUsers}
          />
          <FormControl.Feedback type="invalid">{error.maxNoEnabledUsers}</FormControl.Feedback>
        </Form.Group>
      </Col>
    </Form.Row>
    <Form.Row>
      <Col>
        <Table className={className('table', styles.users)} hover size="sm">
          <thead>
            <tr>
              <th>Name</th><th>Enabled</th><th>Notified</th><th />
            </tr>
          </thead>
          <tbody>
            {users
              .filter((user) => user != newUser)
              .sort((lhs, rhs) => lhs.name.localeCompare(rhs.name))
              .map((user, i) => {
                return <tr key={i}>
                  <td>{user.name}</td>
                  <td><Enabled
                    user={user}
                    error={error}
                    readOnly={readOnly || createNew || !account.enabled}
                    enableUser={enableUser}
                  /></td>
                  <td><Notified user={user} error={error} readOnly={readOnly || createNew || !account.enabled} notifyUser={notifyUser} /></td>
                  <td><Button disabled={readOnly || createNew} className={"btn-sm"} variant="primary" onClick={() => {
                    notifyUser(user._draft)
                  }}>NOTIFY {'>>'}</Button></td>
                </tr>
              })}
          </tbody>
        </Table>

        <Button disabled={readOnly || createNew} className={"mb-2 btn-lg"} variant="primary" onClick={() => {
          setCreateNew(true)
          createUser()
        }}>NEW</Button>
        {
          createNew && newUser && <CreateNew
            user={newUser}
            setDraft={setDraft}
            putUser={(user) => {
              putUser(user)
              setCreateNew(false)
            }}
            putUserError={putUserError}
            putUserPending={putUserPending}
            discardUser={(user) => {
              discardUser(user)
              setCreateNew(false)
            }}
          />
        }

      </Col>
    </Form.Row>
  </>
}

export default Users
