// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".src-components-documentEditor-treeEditor-TreeNodeDropTarget__treeNodeDropTarget--3_blz{height:10px;line-height:10px;margin-left:15px}.src-components-documentEditor-treeEditor-TreeNodeDropTarget__isHovering--1a68_{vertical-align:super;display:inline-block;text-decoration:underline}\n", "",{"version":3,"sources":["C:/Gitlab-Runner/builds/josiD6fY/0/developers/comtax-admin/src/components/documentEditor/treeEditor/TreeNodeDropTarget.scss"],"names":[],"mappings":"AAEA,wFACI,WAAY,CACZ,gBAAiB,CACjB,gBAAiB,CACpB,gFAGG,oBAAqB,CACrB,oBAAqB,CACrB,yBAA0B","file":"TreeNodeDropTarget.scss","sourcesContent":["@import '../../_base.scss';\r\n\r\n.treeNodeDropTarget {\r\n    height: 10px;\r\n    line-height: 10px;    \r\n    margin-left: 15px;\r\n}\r\n\r\n.isHovering {    \r\n    vertical-align: super;\r\n    display: inline-block;\r\n    text-decoration: underline;\r\n}\r\n"]}]);
// Exports
exports.locals = {
	"treeNodeDropTarget": "src-components-documentEditor-treeEditor-TreeNodeDropTarget__treeNodeDropTarget--3_blz",
	"isHovering": "src-components-documentEditor-treeEditor-TreeNodeDropTarget__isHovering--1a68_"
};
module.exports = exports;
