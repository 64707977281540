import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { connect } from 'react-redux'
import { Button, ButtonToolbar, Spinner } from 'react-bootstrap'

import styles from './OngoingPublication.scss'
import { newPublishLog } from '../../state/documents/documentFactoryActions'
import { getUnpublishedLog } from '../../state/documents/documentReducer'
import { getPending, getError } from '../../state/progress/progressReducer'
import { publish, PUBLISH } from '../../state/publish/publishActions'
import DocumentEditor from '../documentEditor/DocumentEditor.jsx'
import Message from '../modals/Message.jsx'

const getPublishing = (log, state) => log && getPending(PUBLISH, log._id)(state)
const getPublishingError = (log, state) => log && getError(PUBLISH, log._id)(state)

const mapStateToProps = (state, props) => {
    const log = getUnpublishedLog(state)
    return {
        log,
        publishing: getPublishing(log, state),
        error: getPublishingError(log, state)
    }
}

const mapDispatchToProps = (dispatch, props) => {
    return {
        newPublishLog: () => dispatch(newPublishLog()),
        publish: (log) => dispatch(publish(log))
    }
}

class OngoingPublication extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            published: false
        }
    }

    componentDidMount() {
        const { log, newPublishLog } = this.props
        if (!log) {
            newPublishLog()
        }
    }

    componentDidUpdate() {
        const { log, newPublishLog } = this.props
        if (!log) {
            newPublishLog()
        }
    }


    render() {
        const { log, publish, publishing, error } = this.props
        const { published } = this.state        
        return <Row noGutters={true}>
            <Message
                show={published && !publishing}
                onHide={() => this.setState({ published: false })}
                title={`PUBLISH ${error ? 'FAILED' : 'SUCCEEDED'}!`}
                body={error ? JSON.stringify(error, null, 2) : 'All is well'}
            />
            <Col>
                <div className={styles.container}>
                    <ButtonToolbar>
                        <Button className={styles.publishButton} variant='danger' onClick={() => {
                            publish(log)
                            this.setState({ published: true })
                        }} disabled={publishing}>
                            {publishing ?
                                <Spinner
                                    as='span'
                                    animation='border'
                                    size='sm'
                                    role='status'
                                    aria-hidden='true'
                                /> : 'Publish'}
                        </Button>
                    </ButtonToolbar>
                    {log && <DocumentEditor documentId={log._id} />}
                </div>
            </Col>
        </Row >
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OngoingPublication)