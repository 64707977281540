export default (url, options) => {
  const allOptions = Object.assign({ credentials: 'include' }, options)

  if (allOptions.body) {
    const headers = {
      'content-type': 'application/json'
    }

    if (options.headers) {
      headers.assign(options.headers)
    }
    allOptions.headers = headers

    if (!(typeof allOptions.body === 'object' || Array.isArray(allOptions.body))) {
      throw new Error('Body only supports object and array')
    }
    allOptions.body = JSON.stringify(allOptions.body)
  }
  return fetch(url, allOptions)
    .then((response) => {
      const contentType = response.headers.get('content-type')
      const parseBody = () => {
        return contentType && contentType.includes('application/json') ?
          response.json() : response.text()
      }

      if (!response.ok) {
        return parseBody()
          .then((body) => Promise.reject({
            url: response.url,
            statusText: body && body.reason ? `${response.statusText}: ${body.reason}` : `${response.statusText}`,
            status: response.status
          }))
      }

      return parseBody()
    })
}
