import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { connect } from 'react-redux'

import HeaderLink from './HeaderLink.jsx'
import { logout } from '../../state/auth/authActions'
import styles from './Header.scss'

const mapStateToProps = (state, props) => {
  return {}
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    logout: () => dispatch(logout())
  }
}

class Login extends React.Component {
  render() {
    return <Row className={styles.header} noGutters={true}>
      <Col lg={{ span: 1 }}>
        <div className={styles.background} />
      </Col>
      <Col lg={{ span: 9 }} >
        <div className={styles.background}>
          <HeaderLink to='/tax-guide' icon={styles.taxGuideIcon}>TAX GUIDE</HeaderLink>
          <HeaderLink to='/notes' icon={styles.notesIcon}>NOTES</HeaderLink>
          <HeaderLink to='/accounts' icon={styles.accountsIcon}>ACCOUNTS</HeaderLink>          
          <HeaderLink to='/statistics' icon={styles.statisticsIcon}>STATISTICS</HeaderLink>
          <HeaderLink to='/settings' icon={styles.settingsIcon}>SETTINGS</HeaderLink>
          <HeaderLink to='/publish' icon={styles.publishIcon}>PUBLISH</HeaderLink>
        </div>
      </Col>
      <Col lg={{ span: 1 }} >
        <div className={styles.background} onClick={() => this.props.logout()}>
          <HeaderLink to='/login' icon={styles.logoutIcon}>LOGOUT</HeaderLink>
        </div>
      </Col>
      <Col lg={{ span: 1 }}>
        <div className={styles.background} />
      </Col>
    </Row>
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
