import React from 'react'
import classNames from 'classnames'
import { Popover, OverlayTrigger, Button, ButtonGroup } from 'react-bootstrap'

import styles from './ConfirmPopover.scss'

export default class ConfirmPopover extends React.Component {
  constructor() {
    super()
    this.state = {
      show: false
    }
    this.overlayRef = React.createRef()
  }

  render() {
    const { title, content, ok = () => {}, cancel = () => {}, placement } = this.props
    //onClick={(e) => { this.overlayRef.hide() }}
    const popover = <Popover>
      <Popover.Title as="h3">{title}</Popover.Title>
      <Popover.Content>
        <div className={styles.content}>{content}</div>
        <ButtonGroup size="sm" className={classNames('w-100')}>
          <Button className={classNames('w-50', 'text-nowrap')} variant="primary" onClick={ok}>OK</Button>
          <Button className={classNames('w-50', 'text-nowrap')} variant="secondary" onClick={cancel}>CANCEL</Button>
        </ButtonGroup>
      </Popover.Content>
    </Popover>
    return <OverlayTrigger
      // ref={(ref) => { this.overlayRef = ref }}
      show={this.state.show}
      onHide={() => this.setState({ show: false })}
      rootClose={true}
      trigger="click"
      placement={placement}
      overlay={popover}>
      {this.props.children}
    </OverlayTrigger>
  }
}
