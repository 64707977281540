import React, { useState } from 'react'
import { Dropdown, FormControl, ListGroup } from 'react-bootstrap'
import classNames from 'classnames'

import styles from './CountrySelector.scss'

const Toggle = React.forwardRef(({ children, onClick }, ref) => (
    <a className={styles.label}
        href=""
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
      &#x25bc;
    </a>
));

const Menu = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
        const [value, setValue] = useState('');

        return (
            <div
                ref={ref}
                style={style}
                className={classNames(className, styles.menu)}
                aria-labelledby={labeledBy}
                style={{ heigth: 200 }}
            >
                <FormControl
                    autoFocus
                    formNoValidate
                    className="mx-3 my-2 w-auto"
                    placeholder="Type to filter..."
                    onChange={(e) => {
                        setValue(e.target.value)
                    }}
                    value={value}
                />
                <ul className="list-unstyled" style={{
                    height: '200px',
                    overflowY: 'scroll',
                    marginBottom: 0,
                    marginLeft: '2px'
                }}>
                    {React.Children.toArray(children).filter(
                        (child) =>
                            !value || child.props.children && child.props.children.toLowerCase().startsWith(value.toLowerCase())
                    )}
                </ul>
            </div>
        );
    },
);


class CountrySelector extends React.Component {
    render() {
        const { onChange, selectedCountries, allCountries, readOnly } = this.props
        return <div className={styles.container}>
            <Dropdown size="sm">
                <Dropdown.Toggle className={styles.label} as={Toggle}>
                    {this.props.children} ({selectedCountries.length})
                </Dropdown.Toggle>
                <Dropdown.Menu as={Menu}>
                    <Dropdown.Item disabled={readOnly} eventKey='ALL' key='ALL' className='p-1' onSelect={() => {
                        onChange([...allCountries.map((country) => country._id)])
                    }}>ALL</Dropdown.Item>
                    <Dropdown.Divider className='p-1' />
                    {
                        allCountries
                            .sort((lhs, rhs) => lhs.name.localeCompare(rhs.name))
                            .filter((country) => !(selectedCountries && selectedCountries.find((data) => data == country._id)))
                            .map((country) => <Dropdown.Item
                                disabled={readOnly}
                                className='p-1'
                                eventKey={country._id}
                                key={country.countryCode}
                                onSelect={(countryId) => {
                                    const selected = selectedCountries ? [...selectedCountries, countryId] : [countryId]
                                    onChange(selected)
                                }}>
                                {country.name}
                            </Dropdown.Item>)
                    }
                </Dropdown.Menu>
            </Dropdown>
            <ListGroup variant="flush" style={{ maxHeight: '400px', overflowY: 'auto' }}>
                {
                    selectedCountries && allCountries
                        .filter((country) => selectedCountries && selectedCountries.find((data) => data == country._id))
                        .sort((lhs, rhs) => lhs.name.localeCompare(rhs.name))
                        .map((country) => <ListGroup.Item disabled={readOnly} action key={country.countryCode} onClick={() => {
                            const i = selectedCountries.indexOf(country._id)
                            onChange([...selectedCountries.slice(0, i), ...selectedCountries.slice(i + 1)])
                        }} className='p-2' >{country.name}</ListGroup.Item>)
                }
            </ListGroup>
        </div >
    }
}

export default CountrySelector
