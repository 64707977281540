import React from 'react'
import { Col, Form, Button } from 'react-bootstrap'

import styles from './BaseInfo.scss'

const BaseInfoEditor = (props) => {
  const { draft, setDraft, error, generateProductKey } = props
  return (<><Form.Row>
    <Col lg={3}>
      <Form.Group controlId="accountName">
        <Form.Label className={styles.label}>Account name</Form.Label>
        <Form.Control
          type="text"
          required
          placeholder="Account name"
          onChange={
            ({ target: { value: name } }) => {
              const newDraft = Object.assign({}, draft, { name })
              setDraft(newDraft)
            }}
          value={draft.name}
          isInvalid={error.name}
          isValid={draft.name && !error.name}
        />
        <Form.Control.Feedback type="invalid">{error.name}</Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="url">
        <Form.Label className={styles.label}>Url</Form.Label>
        <Form.Control
          type="text"
          required
          placeholder="url"
          onChange={
            ({ target: { value: url } }) => {
              const newDraft = Object.assign({}, draft, { url })
              setDraft(newDraft)
            }}
          value={draft.url}
          isInvalid={error.url}
          isValid={draft.url && !error.url}
        />
        <Form.Control.Feedback type="invalid">{error.url}</Form.Control.Feedback>
      </Form.Group>
    </Col>
  </Form.Row>
    <Form.Row>
      <Col lg={3}>
        <Form.Group controlId="productKey">
          <Form.Label className={styles.label}>Product Key</Form.Label>
          <Form.Control
            type="text"
            required
            placeholder="Product Key"
            onChange={
              ({ target: { value: productKey } }) => {
                const newDraft = Object.assign({}, draft, { productKey })
                setDraft(newDraft)
              }}
            value={draft.productKey}
            isInvalid={error.productKey}
            isValid={draft.productKey && !error.productKey}
          />
          <Form.Control.Feedback type="invalid">{error.productKey}</Form.Control.Feedback>
        </Form.Group>
      </Col>
      <Col lg={2}>
        <Form.Group controlId="generateProductKey">
          <Form.Label>&nbsp;</Form.Label>
          <div>
            <Button variant="secondary" onClick={() => {
              generateProductKey(draft)
            }}>NEW</Button>
          </div>
        </Form.Group>
      </Col>
    </Form.Row>
  </>)
}

const BaseInfoReadonly = (props) => {
  const { draft, document, deleteAccount, push } = props
  return <>
    <Form.Row>
      <Col lg={{ offset: 4, span: 4 }}>
        <h1>{draft.name}
          {!document.enabled &&
            <Button className={"ml-2"} variant="danger" onClick={() => {
              deleteAccount(draft._id)
              push("/accounts/disabled")
            }}>DELETE!</Button>}
        </h1>
      </Col>
    </Form.Row>
    <Form.Row>
      <Col lg={3}>
        <div><span className={styles.label}>URL: </span><span className={styles.value}>{draft.url}</span></div>
        <div><span className={styles.label}>Product Key: </span><span className={styles.value}>{draft.productKey}</span></div>
      </Col>
    </Form.Row>
  </>
}

const BaseInfo = (props) => {
  const { readOnly } = props
  return readOnly ? <BaseInfoReadonly {...props} /> : <BaseInfoEditor {...props} />
}

export default BaseInfo