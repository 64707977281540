import React, { useState } from 'react'
import { Table, thead } from 'react-bootstrap'

import styles from './AccountTable.scss'

const AccountTable = (props) => {
  const { accounts = [], onClick = () => { } } = props
  const [sortOn, setSortOn] = useState(["name"])
  const [sortAscending, setSortAscending] = useState(true)

  const getSortValue = (obj, path) => path.length == 1 ? obj[path] : getSortValue(obj[path[0]], path.slice(1))

  const sortedAcccounts = accounts.sort((lhs, rhs) => {
    let comparison
    const lhsValue = getSortValue(lhs, sortOn)
    const rhsValue = getSortValue(rhs, sortOn)

    if (!lhsValue) {
      comparison = -1
    }
    else if (typeof lhsValue === 'string') {
      comparison = lhsValue && lhsValue.localeCompare(rhsValue)
    }
    else {
      comparison = lhsValue - rhsValue
    }

    if (!sortAscending) {
      comparison *= -1
    }
    return comparison
  })

  const setSorting = (path) => {
    if (sortOn.every((s, i) => s === path[i])) {
      setSortAscending(!sortAscending)
    }
    else {
      setSortOn(path)
      setSortAscending(true)
    }
  }


  return <Table className={"mt-2"} striped hover size="lg">
    <thead>
      <tr>
        <th className={styles.sortableHeader} onClick={() => setSorting(["name"])}>
          Name
          </th>
        <th className={styles.sortableHeader} onClick={() => setSorting(["expirationDate_utc"])}>
          Expires
          </th>
        <th className={styles.sortableHeader} onClick={() => setSorting(["maxNoEnabledUsers"])}>
          Users
          </th>
        <th className={styles.sortableHeader} onClick={() => setSorting(["license", "calculator"])}>
          Calculator
          </th>
        <th>
          Modules
          </th>
      </tr>
    </thead >
    <tbody>
      {
        sortedAcccounts.map((account) =>
          <tr className={styles.row} key={account._id} onClick={() => onClick(account)}>
            <td>{account.name}</td>
            <td>{account.expirationDate_utc && account.expirationDate_utc.split('T')[0]}</td>
            <td>{account.maxNoEnabledUsers}</td>
            <td>{account.license && account.license.calculator}</td>
            <td>{account.license && account.license.modules && account.license.modules.length ? account.license.modules.reduce((acc, value) => `${acc}, ${value}`) : ""}</td>
          </tr>
        )
      }
    </tbody>
  </Table >

}

export default AccountTable
