import React from 'react'

import styles from './SubHeader.scss'

class SubHeader extends React.Component {
  render() {
    return <div className={styles.subHeader}>{this.props.children}</div>
  }
}

export default SubHeader
