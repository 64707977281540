import React from 'react'

import { TABLE_BLOCK, TABLE_ROW_BLOCK, TABLE_CELL_BLOCK } from './blockTypes'

export default function renderBlock(node, attributes, children) {
  switch (node.type) {
    case TABLE_BLOCK:
      return (
        <table style={{
          display: 'block',
          margin: '5px auto',
          borderCollapse: 'collapse'
        }}>
          <tbody {...attributes}>{children}</tbody>
        </table>
      )
    case TABLE_ROW_BLOCK:
      return <tr {...attributes}>{children}</tr>
    case TABLE_CELL_BLOCK:
      return <td style={{
        minWidth: '20px',
        border: '1px solid #b1b1b1',
        padding: '0 5px',
        whiteSpace: 'nowrap'
      }} {...attributes}>{children}</td>
  }
}
