import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import PrivateRoute from '../PrivateRoute.jsx'
import Backend from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'

import Header from './Header.jsx'
import TaxGuide from '../taxGuide/TaxGuide.jsx'
import Publish from '../publish/Publish.jsx'
import Notes from '../notes/Notes.jsx'
import Accounts from '../accounts/Accounts.jsx'
import Statistics from '../statistics/Statistics.jsx'
import styles from './Home.scss'

class Home extends React.Component {
  render() {
    return <DndProvider backend={Backend}>
      <div className={styles.container}>
        <Container fluid={true}>
          <Header />
          <Row noGutters={true}>
            <Col lg={{ span: 10, offset: 1 }} >
              <PrivateRoute path="/tax-guide" component={TaxGuide} />
              <PrivateRoute path="/publish" component={Publish} />
              <PrivateRoute path="/notes" component={Notes} />
              <PrivateRoute path="/statistics" component={Statistics} />
              <PrivateRoute path="/accounts" component={Accounts} />              
            </Col>
          </Row>
        </Container>
      </div>
    </DndProvider>
  }
}

export default Home
