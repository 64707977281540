// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".src-components-accounts-AccountEditor-BaseInfo__label--3tZMF{font-weight:bold;white-space:nowrap;letter-spacing:1px;font-weight:700;text-transform:uppercase}.src-components-accounts-AccountEditor-BaseInfo__value--1rO9i{white-space:nowrap}\n", "",{"version":3,"sources":["C:/Gitlab-Runner/builds/josiD6fY/0/developers/comtax-admin/src/components/accounts/AccountEditor/BaseInfo.scss"],"names":[],"mappings":"AAAA,8DACE,gBAAiB,CACjB,kBAAmB,CACnB,kBAAmB,CACnB,eAAgB,CAChB,wBAAyB,CAC1B,8DAGC,kBAAmB","file":"BaseInfo.scss","sourcesContent":[".label {\r\n  font-weight: bold;\r\n  white-space: nowrap;\r\n  letter-spacing: 1px;\r\n  font-weight: 700;\r\n  text-transform: uppercase;\r\n}\r\n\r\n.value {\r\n  white-space: nowrap;\r\n}"]}]);
// Exports
exports.locals = {
	"label": "src-components-accounts-AccountEditor-BaseInfo__label--3tZMF",
	"value": "src-components-accounts-AccountEditor-BaseInfo__value--1rO9i"
};
module.exports = exports;
