import React from 'react'
import { Block } from 'slate'
import { List } from 'immutable'

import Button from '../../../../buttons/Button.jsx'
import { TABLE_BLOCK, TABLE_ROW_BLOCK, TABLE_CELL_BLOCK } from './blockTypes'
import styles from './TableToolbar.scss'

export default class TableToolbar extends React.Component {

  getParentBlock(type) {
    const { value } = this.props
    return value.blocks.first() && value.document.getAncestors(value.blocks.first().key)
      .find((node) => node.type === type)
  }

  insertBlock(block) {
    const { editor } = this.props
    editor.insertBlock(block)
  }

  insertBlockByKey(key, index, block) {
    const { editor } = this.props
    editor.insertNodeByKey(key, index, block)
  }

  getRowIndex() {
    const { value } = this.props
    const key = value.blocks.first().key
    const path = value.document.getPath(key)
    const index = path.get(path.count() - 2)
    return index
  }

  getColumnIndex() {
    const { value } = this.props
    const key = value.blocks.first().key
    const path = value.document.getPath(key)
    const index = path.get(path.count() - 1)
    return index
  }

  render() {
    const insertTable = () => {
      this.insertBlock(Block.create({
        type: TABLE_BLOCK,
        nodes: List([
          Block.create(
            {
              type: TABLE_ROW_BLOCK,
              nodes: List([
                Block.create({
                  type: TABLE_CELL_BLOCK,
                  nodes: List()
                })
              ])
            }
          )
        ])
      }))
    }

    const insertRow = (before = true) => {
      const table = this.getParentBlock(TABLE_BLOCK)
      if (table) {
        const rows = table.filterDescendants((node) => node.type === TABLE_ROW_BLOCK)
        const numOfCells = rows.first().filterDescendants((node) => node.type === TABLE_CELL_BLOCK).count()
        const index = before ? this.getRowIndex() : this.getRowIndex() + 1
        const cells = []
        for (let i = 0; i < numOfCells; ++i) {
          cells.push(Block.create({
            type: TABLE_CELL_BLOCK,
            nodes: List()
          }))
        }
        this.insertBlockByKey(table.key, index, Block.create({
          type: TABLE_ROW_BLOCK,
          nodes: List(cells)
        }))
      }
    }

    const insertColumn = (before = true) => {
      const table = this.getParentBlock(TABLE_BLOCK)
      if (table) {
        const index = before ? this.getColumnIndex() : this.getColumnIndex() + 1
        table.nodes.forEach((row) => {
          this.insertBlockByKey(row.key, index, Block.create({
            type: TABLE_CELL_BLOCK,
            nodes: List()
          }))
        })
      }
    }

    const deleteTable = () => {
      const table = this.getParentBlock(TABLE_BLOCK)
      if (table) {
        const { editor } = this.props
        editor.removeNodeByKey(table.key)
      }
    }

    const deleteRow = () => {
      const table = this.getParentBlock(TABLE_BLOCK)
      const { editor } = this.props
      if (table) {
        const row = this.getParentBlock(TABLE_ROW_BLOCK)
        editor.removeNodeByKey(row.key)
      }
    }

    const deleteColumn = () => {
      const table = this.getParentBlock(TABLE_BLOCK)
      if (table) {
        const { editor } = this.props
        const index = this.getColumnIndex()
        table.nodes.forEach((row) => {
          const cell = row.nodes.get(index)
          editor.removeNodeByKey(cell.key)
        })
      }
    }

    const insideTable = !!this.getParentBlock(TABLE_BLOCK)

    return <span className={styles.container}>
      <Button icon={styles.addTableButtonIcon} enabled={!insideTable} onClick={insertTable} label="Insert Table" />
      <Button icon={styles.addRowBeforeIcon} enabled={insideTable} onClick={insertRow} label="Insert Row Before" />
      <Button icon={styles.addRowAfterIcon} enabled={insideTable} onClick={() => insertRow(false)} label="Insert Row After" />
      <Button icon={styles.addColBeforeIcon} enabled={insideTable} onClick={insertColumn} label="Insert Column Before" />
      <Button icon={styles.addColAfterIcon} enabled={insideTable} onClick={() => insertColumn(false)} label="Insert Column After" />
      <Button icon={styles.deleteTableButtonIcon} enabled={insideTable} onClick={deleteTable} label="Delete Table" />
      <Button icon={styles.deleteRowIcon} enabled={insideTable} onClick={deleteRow} label="Delete Row" />
      <Button icon={styles.deleteColIcon} enabled={insideTable} onClick={deleteColumn} label="Delete Column" />
    </span>
  }
}
