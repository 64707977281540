// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".src-components-publish-Archive__listGroupContainer--HsOBz{margin-top:3em;max-height:600px;overflow-y:auto}.src-components-publish-Archive__listGroup--1AOdD{white-space:nowrap}\n", "",{"version":3,"sources":["C:/Gitlab-Runner/builds/josiD6fY/0/developers/comtax-admin/src/components/publish/Archive.scss"],"names":[],"mappings":"AAAA,2DACI,cAAe,CACf,gBAAiB,CACjB,eAAgB,CACnB,kDAGG,kBAAmB","file":"Archive.scss","sourcesContent":[".listGroupContainer {\r\n    margin-top: 3em;\r\n    max-height: 600px;\r\n    overflow-y: auto;\r\n}\r\n\r\n.listGroup {      \r\n    white-space: nowrap;\r\n}"]}]);
// Exports
exports.locals = {
	"listGroupContainer": "src-components-publish-Archive__listGroupContainer--HsOBz",
	"listGroup": "src-components-publish-Archive__listGroup--1AOdD"
};
module.exports = exports;
