import React from 'react'

import styles from './HeaderSelect.scss'

export default function HeaderSelect(props) {
  const { headers, hasBlock, setBlock } = props
  const { type } = headers.find((header) => hasBlock(header.type)) || headers[0]
  return (
    <span className={styles.container} title='Header'>
      <span className={styles.label}>
        H
      </span>
      <select className={styles.select}
        onChange={(e) => setBlock(e.currentTarget.value)}
        value={type} >
        {
          headers
            .map((header) =>
              <option key={header.label} value={header.type}>{header.label}</option>
            )
        }
      </select>

    </span>
  )
}
