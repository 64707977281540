import React from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import { withRouter, Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'

import SubHeader from '../subHeader/SubHeader.jsx'
import SubHeaderLink from '../subHeader/SubHeaderLink.jsx'
import { hasChanges } from '../../utils/documentUtils'
import { getDocuments, getDocumentById } from '../../state/documents/documentReducer'
import { newAccount } from '../../state/documents/documentFactoryActions.js'
import AccountEditor from './AccountEditor/AccountEditor.jsx'
import AccountTable from './AccountTable.jsx'
import styles from './Accounts.scss'

const mapDispatchToProps = (dispatch, props) => {
    return {
        push: (url) => props.history.push(url),
        newAccount: () => dispatch(newAccount())
    }
}

const getAllAccounts = getDocuments('account')

const mapStateToProps = (state, props) => {
    return {
        accounts: getAllAccounts(state),
        onClick: (account) =>  props.history.push(`/accounts/edit/${account._id}`)
    }
}

class Accounts extends React.Component {
    constructor() {
        super()
        this.state = {
            selected: ''
        }
    }

    render() {
        const { newAccount, onClick, push, accounts } = this.props;
        return <div className={styles.container}>
            <Row>
                <Col>
                    <SubHeader>
                        <Switch>
                            <Route exact path="/accounts">
                                <SubHeaderLink to='/accounts/enabled'>ENABLED</SubHeaderLink>
                                <SubHeaderLink to='/accounts/disabled'>DISABLED</SubHeaderLink>
                                <Button className={"ml-2"} variant="primary" onClick={() => {
                                    const account = newAccount()
                                    push(`/accounts/edit/${account.payload.document._id}`)
                                }}>NEW</Button>
                            </Route>
                            <Route exact path={["/accounts/enabled", "/accounts/disabled"]}>
                                <SubHeaderLink to='/accounts/enabled'>ENABLED</SubHeaderLink>
                                <SubHeaderLink to='/accounts/disabled'>DISABLED</SubHeaderLink>
                            </Route>
                        </Switch>
                    </SubHeader>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Route path="/accounts/edit/:id" render={({ match }) => {
                        return <AccountEditor accountId={match.params.id} push={push} />
                    }} />
                    <Route path="/accounts/enabled" render={({ match }) => {
                        return <AccountTable
                            onClick={onClick}
                            accounts={accounts.filter(account => account.enabled)} />
                    }} />
                    <Route path="/accounts/disabled" render={({ match }) => {
                        return <AccountTable
                            onClick={onClick}
                            accounts={accounts.filter(account => !account.enabled)} />
                    }} />
                </Col>
            </Row>
        </div >
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Accounts))
