import React from 'react'

import styles from './FullScreenOverlay.scss'

export default class FullScreenOverlay extends React.Component {
  render() {
    const { message } = this.props
    return <div className={styles.container}>
      <div className={styles.overlay}>

      </div>
      <div className={styles.ring}><div></div><div></div><div></div><div></div></div>
      {message && <div className={styles.message}>{message}</div>}
    </div>
  }
}
