import React, { useState } from 'react'
import { Table, thead } from 'react-bootstrap'

import styles from './StatisticsTable.scss'

const UpdateTable = (props) => {
    const { statistics = [], onClick = () => { } } = props
    const [sortOn, setSortOn] = useState(["loginTime"])
    const [sortAscending, setSortAscending] = useState(true)

    const getSortValue = (obj, path) => path.length == 1 ? obj[path] : getSortValue(obj[path[0]], path.slice(1))

    const sortedStatisticss = statistics.sort((lhs, rhs) => {
      let comparison
      const lhsValue = getSortValue(lhs, sortOn)
      const rhsValue = getSortValue(rhs, sortOn)    

      if (!lhsValue) {
        comparison = -1
      }
      else if (typeof lhsValue === 'string') {
        comparison = lhsValue && lhsValue.localeCompare(rhsValue)
      }
      else {
        comparison = lhsValue - rhsValue
      }
  
      if (!sortAscending) {
        comparison *= -1
      }
      return comparison
    })

    const setSorting = (path) => {
        if (sortOn.every((s, i) => s === path[i])) {
          setSortAscending(!sortAscending)
        }
        else {
          setSortOn(path)
          setSortAscending(true)
        }
      }
    
    return <Table className={"mt-2"} striped hover size="lg">
    <thead>
      <tr>
        <th className={styles.sortableHeader} onClick={() => setSorting(["accountName"])}>
          Account Name
          </th>
        <th className={styles.sortableHeader} onClick={() => setSorting(["accountId"])}>
          Account Id
          </th>
        <th className={styles.sortableHeader} onClick={() => setSorting(["product"])}>
          Product
          </th>
        <th className={styles.sortableHeader} onClick={() => setSorting(["userName"])}>
          User Name
          </th>
        <th className={styles.sortableHeader} onClick={() => setSorting(["userId"])}>
          User Id
          </th>
        <th className={styles.sortableHeader} onClick={() => setSorting(["loginTime"])}>
          Login time
        </th>        
      </tr>
    </thead >
    <tbody>
      {
        sortedStatisticss.map((statistics) =>
          <tr className={styles.row} key={statistics.id}onClick={() => onClick(statistics)}>
            <td>{statistics.accountName}</td>
            <td>{statistics.accountId}</td>
            <td>{statistics.product}</td>
            <td>{statistics.userName}</td>
            <td>{statistics.userId}</td>            
            <td>{statistics.loginTime}</td>
          </tr>
        )
      }
    </tbody>
  </Table >

}

export default UpdateTable