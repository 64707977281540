import React, { useState, useRef, useLayoutEffect, useEffect } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Form, Button, InputGroup, FormControl } from 'react-bootstrap'
import deepEqual from 'deep-equal'

import BaseInfo from './BaseInfo.jsx'
import Status from './Status.jsx'
import License from './License.jsx'
import Users from './Users.jsx'
import { newUser } from '../../../state/documents/documentFactoryActions.js'
import { getDocumentById, getDocuments } from '../../../state/documents/documentReducer'
import { getAccountUsers } from '../../../state/users/userReducer'
import { setDraft, discardDraft, discardDocument, createDraft } from '../../../state/documents/documentActions'
import { generateProductKey, putAccount, deleteAccount, PUT_ACCOUNT } from '../../../state/accounts/accountActions'
import { putUser, PUT_USER, notifyUser, enableUser } from '../../../state/users/userActions'
import { getPending, getError } from '../../../state/progress/progressReducer'
import { hasChanges, hasError, isPending } from '../../../utils/documentUtils'
import SaveButton from '../../buttons/SaveButton.jsx'
import DustbinButton from '../../buttons/DustbinButton.jsx'
import PutAccountDialog from './PutAccountDialog.jsx'
import styles from './AccountEditor.scss'

const mapDispatchToProps = (dispatch, props) => {
  return {
    setDraft: (draft) => dispatch(setDraft(draft)),
    discardDraft: (draft) => dispatch(discardDraft(draft)),
    discardDocument: (draft) => dispatch(discardDocument(draft)),
    deleteAccount: (document) => dispatch(deleteAccount(document)),
    createDraft: (document) => dispatch(createDraft(document)),
    putUser: (user) => dispatch(putUser(user)),
    enableUser: (user) => dispatch(enableUser(user)),
    putAccount: (document, username, password) => dispatch(putAccount(document, username, password)),
    notifyUser: (user) => dispatch(notifyUser(user)),
    generateProductKey: (draft) => dispatch(generateProductKey(draft)),
    newUser: (accountId) => dispatch(newUser(accountId))
  }
}

const mapStateToProps = (state, props) => {
  return {
    putAccountError: getError(PUT_ACCOUNT, props.accountId)(state),
    putAccountPending: getPending(PUT_ACCOUNT, props.accountId)(state),
    account: getDocumentById(props.accountId)(state),
    countries: getDocuments('country')(state),
    putUserError: getError(PUT_USER, props.accountId)(state),
    putUserPending: getPending(PUT_USER, props.accountId)(state),    
    users: getAccountUsers(props.accountId)(state)
  }
}
const AccountEditor = (props) => {
  const {
    account: document, account: { _progress = {} },
    account: { _draft: draft },
    countries,
    setDraft,
    enableUser,
    putUser,
    putUserError,
    putUserPending,
    notifyUser,
    discardDraft,
    createDraft,
    discardDocument,
    deleteAccount,
    generateProductKey,
    users,
    newUser,
    putAccount,
    putAccountError,
    putAccountPending,
    push } = props
  const { error = {} } = _progress
  const newAccount = !!!document._rev
  const [validated, setValidated] = useState(false)
  const [showPutAccountDialog, setShowPutAccountDialog] = useState(false)
  useLayoutEffect(() => {
    if (!draft) {
      setValidated(false)
      createDraft(document)
    }
    users
      .filter((user) => !user._draft)
      .forEach((user) => createDraft(user))
  })


  const SaveOrDiscardButtons = (props) => {
    const saveButtonRef = useRef(null)
    useEffect(() => {
      if (saveButtonRef.current) {
        saveButtonRef.current.scrollIntoView()
      }
    })

    return <div>
      {hasChanges(document) && !hasError(document) &&
        <div ref={saveButtonRef}>
          <SaveButton save={(event) => {
            const valid = formRef.current.checkValidity()
            if (valid) {
              setShowPutAccountDialog(true)
            }
            else {
              event.preventDefault();
              event.stopPropagation();
            }
            setValidated(true)
          }} saving={isPending(document)} error={hasError(document) || putAccountError} />
          {!isPending(document) && <DustbinButton discard={() => discardDraft(draft)} />}
        </ div>}
    </div>
  }

  const baseInfoReadonly = !newAccount
  const showBaseInfo = draft

  const showStatus = draft && baseInfoReadonly
  const statusHasChanges = draft &&
    (draft.expirationDate_utc !== document.expirationDate_utc
      || draft.enabled !== document.enabled)

  const showLicense = draft && baseInfoReadonly
  const licenseHasChanges = draft &&
    (draft.license.calculator !== document.license.calculator
      || !deepEqual(draft.license.modules, document.license.modules)
      || !deepEqual(draft.license.countries, document.license.countries))

  const showUsers = draft && baseInfoReadonly
  const usersHasChanges = draft &&
    (draft.maxNoEnabledUsers !== document.maxNoEnabledUsers)
    || users.some(user => hasChanges(user))

  const statusReadonly = licenseHasChanges || usersHasChanges
  const licenseReadonly = statusHasChanges || usersHasChanges
  const usersReadonly = licenseHasChanges || statusHasChanges

  const formRef = React.useRef()  
  return (
    <Form ref={formRef} noValidate validated={validated} className="mt-2">
      <Form.Row>
        <Col>
          <PutAccountDialog show={showPutAccountDialog || putAccountPending}
            hide={() => setShowPutAccountDialog(false)}
            putAccount={(username, password) => {
              putAccount(draft, username, password) 
              setShowPutAccountDialog(false)                           
            }}
            errorPutAccount={putAccountError}
            pendingPutAccount={putAccountPending}
          />
          {showBaseInfo &&
            <BaseInfo
              document={document}
              draft={draft}
              setDraft={setDraft}
              error={error}
              generateProductKey={generateProductKey}
              readOnly={baseInfoReadonly}
              deleteAccount={() => deleteAccount(document)}
              push={push}
            />}
          {!baseInfoReadonly &&
            <SaveOrDiscardButtons needPassword={true} />}
        </Col>
      </Form.Row>
      <Form.Row>
        <Col lg={3}>
          {showStatus &&
            <Status draft={draft}
              document={document}
              setDraft={setDraft}
              error={error}
              readOnly={statusReadonly} />}
          {showStatus && statusHasChanges &&
            <SaveOrDiscardButtons />}

          {showLicense &&
            <License
              draft={draft}
              document={document}
              setDraft={setDraft}
              error={error}
              countries={countries}
              readOnly={licenseReadonly} />}
          {showLicense && licenseHasChanges &&
            <div className="mt-2"><SaveOrDiscardButtons /></div>}
        </Col>
        <Col lg={{ span: 8, offset: 1 }} className="mt-4">
          {showUsers && <Users account={document} error={error}
            setDraft={setDraft} 
            users={users}
            createDraft={createDraft}
            putUserError={putUserError}             
            putUserPending={putUserPending}
            notifyUser={notifyUser}  
            enableUser={enableUser}         
            readOnly={usersReadonly}
            createUser={() => newUser(document._id)}
            putUser={(user) => putUser(user)}
            discardUser={(user) => discardDocument(user)} />}
          {showUsers && draft.maxNoEnabledUsers !== document.maxNoEnabledUsers &&
            <SaveOrDiscardButtons />}
        </Col>
      </Form.Row>
    </Form >
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountEditor)