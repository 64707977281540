export const PREFIX = 'publish'

export const PUBLISH = `${PREFIX}`

export function publish(log) {
    return {
      type: PUBLISH,
      payload: { log }
    }
  }
