import React from 'react'
import classNames from 'classnames'

import styles from './Lock.scss'

export default class Lock extends React.PureComponent {
  render() {
    const { opened, open, close } = this.props
    return <div title={opened ? 'Click to lock' : 'Click to unlock'} className={styles.container}>
      <div
        onClick={() => opened ? (close && close()) : (open && open())}
        className={classNames(styles.lock, { [styles.opened]: opened, [styles.closed]: !opened })}>
      </div>
    </div>
  }
}
