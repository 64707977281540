import React from 'react'

import {
  HEADER_ONE, HEADER_TWO, HEADER_THREE, HEADER_FOUR, HEADER_FIVE,
  BLOCK_QUOTE, NUMBERED_LIST, BULLETED_LIST, LIST_ITEM
} from './blockTypes'

export default function renderBlock(node, attributes, children) {
  switch (node.type) {
    case BLOCK_QUOTE:
      return <div style={{
        borderLeft: '5px solid #eee',
        color: '#666',
        fontFamily: 'Hoefler Text, Georgia, serif',
        fontStyle: 'italic',
        margin: '16px 0',
        padding: '10px 20px'
      }} {...attributes}>{children}</div>
    case BULLETED_LIST:
      return <ul {...attributes}>{children}</ul>
    case HEADER_ONE:
      return <h1 {...attributes}>{children}</h1>
    case HEADER_TWO:
      return <h2 {...attributes}>{children}</h2>
    case HEADER_THREE:
      return <h3 {...attributes}>{children}</h3>
    case HEADER_FOUR:
      return <h4 {...attributes}>{children}</h4>
    case HEADER_FIVE:
      return <h5 {...attributes}>{children}</h5>
    case LIST_ITEM:
      return <li {...attributes}>{children}</li>
    case NUMBERED_LIST:
      return <ol {...attributes}>{children}</ol>
  }
}
