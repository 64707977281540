import React from 'react'
import { Col, Form, Button } from 'react-bootstrap'

import styles from './Status.scss'

const BaseInfoEditor = (props) => {
  const { draft, document, setDraft, error, readOnly } = props

  return <>
    <Form.Row className="mt-4">
      <Col lg={6}>
        <Form.Group controlId="expirationDate">
          <Form.Label className={styles.label}>Expiration Date</Form.Label>
          <Form.Control
            readOnly={readOnly}
            type="date"
            required
            onChange={
              ({ target: { value: expirationDate_utc } }) => {
                const newDraft = Object.assign({}, draft, { expirationDate_utc: expirationDate_utc + 'T00:00:00.000+01:00' })
                setDraft(newDraft)
              }}
            value={draft.expirationDate_utc.split('T')[0]}
            isInvalid={error.expirationDate_utc}
            isValid={!error.expirationDate_utc && draft.expirationDate_utc !== document.expirationDate_utc}
          />
          <Form.Control.Feedback type="invalid">{error.expirationDate_utc}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="enabled">
          <Form.Check
            className={styles.label}
            disabled={readOnly}
            label="Enabled"
            size="lg"
            checked={draft.enabled}
            onChange={
              ({ target: { checked: enabled } }) => {
                const newDraft = Object.assign({}, draft, { enabled })
                setDraft(newDraft)
              }}
            isInvalid={error.enabled}
          //isValid={!error.enabled && draft.enabled !== document.enabled}
          />
          <Form.Control.Feedback type="invalid">{error.enabled}</Form.Control.Feedback>
        </Form.Group>
      </Col>
    </Form.Row>
  </>
}

export default BaseInfoEditor
