import React, { useState, useEffect } from 'react'
import Form from 'react-jsonschema-form'
import { DropdownButton, Dropdown } from 'react-bootstrap'
import PropTypes from 'prop-types'

import styles from './Tagging.scss'
import schema from '../../../data/tags/schema.tags.json'

const ObjectFieldTemplate = (setFormData) => (props) => {
    const { formData, schema } = props
    const formDataPropertyNames = Object.getOwnPropertyNames(formData)
    const schemaPropertyNames = Object.getOwnPropertyNames(schema.properties)

    const unassignedTags = schemaPropertyNames.filter((prop) => !formDataPropertyNames.includes(prop))
    return <div key={schema.$id} className={styles.tags}>
        {props.properties
            .filter((element) => formDataPropertyNames.includes(element.name))
            .map((element) => {
                return <span key={element.name} className={styles.tag}>
                    <span className={styles.delete} onClick={() => {
                        const newFormData = Object.assign({}, formData)
                        delete newFormData[element.name]
                        setFormData(newFormData)
                    }}></span>{element.content}
                </span>
            })}
        {!!unassignedTags.length && <DropdownButton id='tagging_1' title="Tag" onSelect={(prop) => setFormData(Object.assign({}, formData, { [prop]: schema.properties[prop].enum[0] }))}>
            {unassignedTags.map((prop) => <Dropdown.Item key={prop} eventKey={prop}>{prop}</Dropdown.Item>)}
        </DropdownButton>}
    </div>
}

const uiSchema = {}

const Tagging = ({ tags, onChange }) => {
    const [formData, setFormData] = useState(tags)
    useEffect(() => {        
        setFormData(tags)
    }, [tags])
    return <Form schema={schema}
        idPrefix='tagging'
        formData={formData}
        uiSchema={uiSchema}
        ObjectFieldTemplate={ObjectFieldTemplate((newFormData) => {
            onChange(newFormData)
            setFormData(newFormData)
        })}
        onChange={value => {
            onChange(value.formData);
        }}>
        <></>
    </Form >
}

Tagging.propTypes = {
    tags: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired
}

export default Tagging
