// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".src-components-publish-OngoingPublication__container--2nd99{padding-top:3em}.src-components-publish-OngoingPublication__publishButton--3t4E8{width:6em}\n", "",{"version":3,"sources":["C:/Gitlab-Runner/builds/josiD6fY/0/developers/comtax-admin/src/components/publish/OngoingPublication.scss"],"names":[],"mappings":"AAAA,6DACI,eAAgB,CACnB,iEAGG,SAAU","file":"OngoingPublication.scss","sourcesContent":[".container {\r\n    padding-top: 3em;\r\n}\r\n\r\n.publishButton {\r\n    width: 6em;\r\n}"]}]);
// Exports
exports.locals = {
	"container": "src-components-publish-OngoingPublication__container--2nd99",
	"publishButton": "src-components-publish-OngoingPublication__publishButton--3t4E8"
};
module.exports = exports;
