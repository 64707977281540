import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './Button.scss'

export default class Button extends React.Component {
  render() {
    const { label, enabled = true, icon = '', onClick, className = {} } = this.props
    return (
      <span className={classNames(styles.button, icon, className, { [styles.disabled]: !enabled })}
        onMouseDown={(e) => {
          if (enabled) {
            onClick(e)
            e.preventDefault()
          }
        }}
        title={this.props.label}>
        {!icon && label}
      </span>
    )
  }
}

Button.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  enabled: PropTypes.bool,
  className: PropTypes.object,
  icon: PropTypes.string
}
