import { isKeyHotkey } from 'is-hotkey'
import renderMark from './renderMark'

import { BOLD_MARK, UNDERLINE_MARK, ITALIC_MARK } from './markTypes'

const isBoldHotkey = isKeyHotkey('mod+b')
const isItalicHotkey = isKeyHotkey('mod+i')
const isUnderlinedHotkey = isKeyHotkey('mod+u')

export default function RTFPlugin(options) {
  return {
    onKeyDown(event, editor, next) {
      let mark
      if (isBoldHotkey(event)) {
        mark = BOLD_MARK
      } else if (isItalicHotkey(event)) {
        mark = ITALIC_MARK
      } else if (isUnderlinedHotkey(event)) {
        mark = UNDERLINE_MARK
      } else {
        return next()
      }

      event.preventDefault()
      editor.toggleMark(mark)
    },
    renderMark(props, editor, next) {
      const { children, mark, attributes } = props
      return renderMark(mark, attributes, children) || next()
    }
  }
}
