import React from 'react'

import styles from './Toolbar.scss'

export default class Toolbar extends React.Component {
  render() {
    return <div className={styles.toolbar}>
      {this.props.children}
    </div>
  }
}
