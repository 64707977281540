const prefix = 'progress'
export const PENDING = `${prefix}/pending`
export const ERROR = `${prefix}/error`
export const DONE = `${prefix}/done`
export const CLEAR = `${prefix}/clear`

export function pending(report, ...path) {
  return {
    type: PENDING,
    payload: { path, report }
  }
}

export function error(error, ...path) {
  return {
    type: ERROR,
    payload: { path, error }
  }
}

export function clear(...path) {
  return {
    type: CLEAR,
    payload: { path }
  }
}

export function done(...path) {
  return {
    type: DONE,
    payload: { path }
  }
}
