export const PREFIX = 'document'

export const SET_DOCUMENT = `${PREFIX}/set_document`
export const SET_DOCUMENTS = `${PREFIX}/set_documents`
export const CREATE_DOCUMENT = `${PREFIX}/create_document`
export const CREATE_DRAFT = `${PREFIX}/create_draft`
export const DISCARD_DRAFT = `${PREFIX}/discard_draft`
export const DISCARD_DOCUMENT = `${PREFIX}/discard_document`
export const SET_DRAFT = `${PREFIX}/set_draft`

export const PUT_DOCUMENT = `${PREFIX}/db/put_document`
export const REMOVE_DOCUMENT = `${PREFIX}/db/remove_document`

export function setDocument(document) {
  return {
    type: SET_DOCUMENT,
    payload: { document }
  }
}

export function setDocuments(documents) {
  return {
    type: SET_DOCUMENTS,
    payload: { documents }
  }
}

export function createDraft(document) {
  return {
    type: CREATE_DRAFT,
    payload: { document }
  }
}

export function createDocument(document) {
  return {
    type: CREATE_DOCUMENT,
    payload: { document }
  }
}

export function discardDocument(document) {
  return {
    type: DISCARD_DOCUMENT,
    payload: { document }
  }
}

export function discardDraft(draft) {
  return {
    type: DISCARD_DRAFT,
    payload: { draft }
  }
}

export function setDraft(draft) {
  return {
    type: SET_DRAFT,
    payload: { draft }
  }
}

export function putDocument(document, delay) {
  return {
    type: PUT_DOCUMENT,
    payload: { document, delay }
  }
}

export function removeDocument(document) {
  return {
    type: REMOVE_DOCUMENT,
    payload: { document }
  }
}