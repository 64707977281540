// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".src-components-accounts-AccountEditor-Users__label--1exHf,.src-components-accounts-AccountEditor-Users__users--II5EJ th{font-weight:bold;white-space:nowrap;letter-spacing:1px;font-weight:700;text-transform:uppercase}.src-components-accounts-AccountEditor-Users__users--II5EJ th{text-align:left;padding:0 1em 0 0}\n", "",{"version":3,"sources":["C:/Gitlab-Runner/builds/josiD6fY/0/developers/comtax-admin/src/components/accounts/AccountEditor/Users.scss"],"names":[],"mappings":"AAAA,yHACE,gBAAiB,CACjB,kBAAmB,CACnB,kBAAmB,CACnB,eAAgB,CAChB,wBAAyB,CAC1B,8DAIG,eAAgB,CAEhB,iBAAkB","file":"Users.scss","sourcesContent":[".label {\r\n  font-weight: bold;\r\n  white-space: nowrap;\r\n  letter-spacing: 1px;\r\n  font-weight: 700;\r\n  text-transform: uppercase;\r\n}\r\n\r\n.users {\r\n  th {\r\n    text-align: left;\r\n    @extend .label;\r\n    padding: 0 1em 0 0;\r\n  }\r\n  // td {\r\n  //   text-align: center;\r\n  // }\r\n}"]}]);
// Exports
exports.locals = {
	"label": "src-components-accounts-AccountEditor-Users__label--1exHf",
	"users": "src-components-accounts-AccountEditor-Users__users--II5EJ"
};
module.exports = exports;
