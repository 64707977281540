import React, { useState } from 'react'
import { Dropdown, FormControl, ListGroup } from 'react-bootstrap'
import classNames from 'classnames'

import styles from './CountrySelector.scss'

const Toggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
        href=""
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
      &#x25bc;
    </a>
));

const Menu = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
        const [value, setValue] = useState('');

        return (
            <div
                ref={ref}
                style={style}
                className={classNames(className, styles.menu)}
                aria-labelledby={labeledBy}
                style={{ heigth: 200 }}
            >
                <FormControl
                    autoFocus
                    className="mx-3 my-2 w-auto"
                    placeholder="Type to filter..."
                    onChange={(e) => {
                        setValue(e.target.value)
                    }}
                    value={value}
                />
                <ul className="list-unstyled" style={{
                    height: '200px',
                    overflowY: 'scroll',
                    marginBottom: 0,
                    marginLeft: '2px'
                }}>
                    {React.Children.toArray(children).filter(
                        (child) =>
                            !value || child.props.children && child.props.children.toLowerCase().startsWith(value.toLowerCase()),
                    )}
                </ul>
            </div>
        );
    },
);


class CountrySelector extends React.Component {
    render() {
        const { onChange, selectedCountries, allCountries } = this.props
        return <div className={styles.container}>
            <Dropdown size="sm">
                <Dropdown.Toggle as={Toggle}>
                    {this.props.children}
                </Dropdown.Toggle>
                <Dropdown.Menu as={Menu}>
                    <Dropdown.Item eventKey='ALL' style={{ margin: '3px', width: '95%' }} onSelect={() => {
                        onChange([...countries.map((country) => country.countryCode)])
                    }}>ALL</Dropdown.Item>
                    <Dropdown.Divider />
                    {
                        allCountries
                            .sort((lhs, rhs) => lhs.name.localeCompare(rhs.name))
                            .filter((country) => !(selectedCountries && selectedCountries.find((data) => data == country.countryCode)))
                            .map((country) => <Dropdown.Item
                                style={{ margin: '3px', width: '95%' }}
                                eventKey={country.countryCode}
                                onSelect={(countryCode) => {
                                    const selected = selectedCountries ? [...selectedCountries, countryCode] : [countryCode]
                                    onChange(selected)
                                }}>
                                {country.name}
                            </Dropdown.Item>)
                    }
                </Dropdown.Menu>
            </Dropdown>
            <ListGroup variant="flush" style={{ maxHeight: '400px', overflowY: 'auto' }}>
                {
                    selectedCountries && allCountries
                        .filter((country) => selectedCountries && selectedCountries.find((data) => data == country.countryCode))
                        .sort((lhs, rhs) => lhs.name.localeCompare(rhs.name))
                        .map((country) => <ListGroup.Item action onClick={() => {
                            const i = selectedCountries.indexOf(country.countryCode)
                            onChange([...selectedCountries.slice(0, i), ...selectedCountries.slice(i + 1)])
                        }}>{country.name}</ListGroup.Item>)
                }
            </ListGroup>
        </div >
    }
}

export default CountrySelector
