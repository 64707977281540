import React from 'react'

import SaveButton from '../../buttons/SaveButton.jsx'
import ConfirmPopover from '../../popovers/ConfirmPopover.jsx'
import Lock from './Lock.jsx'
import styles from './StructureEditor.scss'
import { createSection } from '../../../state/documents/documentFactoryActions'
import { hasStructuralChanges } from '../../../utils/documentUtils'
import TreeEditor from '../treeEditor/TreeEditor.jsx'

const StructureLock = (props) => {
  const {
    locked,
    unlock,
    lock,
    hasChanges,
    saveChanges,
    discardChanges
  } = props

  if (locked) {
    return <Lock opened={false} open={unlock} />
  } else {
    return hasChanges ?
      <ConfirmPopover key='save' title="Do you want to save your changes?" placement="right"
        content='Are you sure you do you not want to save your structural changes?'
        ok={saveChanges}
        cancel={discardChanges}
      >
        <div className={styles.lock}>
          <Lock opened={true} />
        </div>
      </ConfirmPopover> :
      <Lock opened={true} close={lock} />
  }
}

export default class StructureEditor extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      locked: true,
      tree: this.mapSectionsToTree(props.document.sections)
    }
  }

  componentDidUpdate(prevProps) {
    const { document } = this.props
    if (document._id !== prevProps.document._id ||
      (document._rev !== prevProps.document._rev)) {
      this.setState(() => ({
        tree: this.mapSectionsToTree(document.sections),
        locked: true
      }))
    }
  }

  discardChanges() {
    const { document } = this.props
    this.props.discardChanges(document)
    this.setState({
      tree: this.mapSectionsToTree(document.sections),
      locked: true
    })
  }

  handleTreeDataChange(newTree) {
    const draft = { ...this.props.document }
    draft.sections = this.mapTreeToSections(newTree)    
    this.props.onChange(draft)
  }

  mapSectionsToTree(sections) {
    return sections.map((section) => {
      const node = Object.assign({}, section)
      if (section.subSections) {
        node.children = this.mapSectionsToTree(section.subSections)
        //delete section.subSections
      }
      return node
    })
  }

  mapTreeToSections(tree) {
    return (tree.nodes || tree).map((node) => {
      const section = {
        name: node.name,
        value: node.data.value,
        html: node.data.html,
        sectionId: node.data.sectionId,
        tags: node.data.tags
      }
      if (node.children) {
        section.subSections = this.mapTreeToSections(node.children)
      }
      return section
    })
  }

  newSection(name) {
    return Object.assign({}, createSection(''), { name })
  }

  render() {
    const { tree, locked } = this.state
    const { disabled, progress, saveChanges, navigate, hasChanges } = this.props            
    
    return <div>
      <StructureLock
        locked={locked || disabled}
        unlock={() => {
          if (!disabled) {
            this.setState({ locked: false })            
          }
        }}
        lock={() => this.setState({ locked: true })}
        hasChanges={hasChanges}
        saveChanges={() => saveChanges()}
        discardChanges={() => this.discardChanges()}
      />
      {hasChanges && <SaveButton save={() => {
        saveChanges()
      }} saving={progress.pending} error={progress.error} />}
      <TreeEditor
        readonly={locked}
        disabled={disabled}
        createData={(name) => this.newSection(name)}
        onClick={(data) => {
          if (locked && !disabled) {
            navigate(data.sectionId)
          }
        }}
        tree={tree}
        onChange={(newTree) => this.handleTreeDataChange(newTree)} />
    </div >
  }
}
