// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".src-components-statistics-StatisticsTable__sortableHeader--2jfYp{cursor:pointer}.src-components-statistics-StatisticsTable__sortableHeader--2jfYp:hover{text-decoration:underline}.src-components-statistics-StatisticsTable__row--1VZzK{cursor:pointer}\n", "",{"version":3,"sources":["C:/Gitlab-Runner/builds/josiD6fY/0/developers/comtax-admin/src/components/statistics/StatisticsTable.scss"],"names":[],"mappings":"AAAA,kEACI,cAAe,CADnB,wEAGM,yBAA0B,CAC3B,uDAID,cAAe","file":"StatisticsTable.scss","sourcesContent":[".sortableHeader {\r\n    cursor: pointer;\r\n    &:hover {\r\n      text-decoration: underline;\r\n    }\r\n  }\r\n  \r\n  .row {\r\n    cursor: pointer;  \r\n  }"]}]);
// Exports
exports.locals = {
	"sortableHeader": "src-components-statistics-StatisticsTable__sortableHeader--2jfYp",
	"row": "src-components-statistics-StatisticsTable__row--1VZzK"
};
module.exports = exports;
