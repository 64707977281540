import deepEqual from 'deep-equal'
import deepDiff from 'deep-diff'

export const hasChanges = (document) => {
  if (!document._draft) {
    return false
  }
  const { _draft, _progress, ...rest } = document
  const equal = deepEqual(rest, _draft)  
  // if (!equal) {
  //   const de = deepEqual
  //   const lhs = JSON.stringify(_draft, null, 2)
  //   const rhs = JSON.stringify(rest, null, 2)
  //   const diff = deepDiff(rest, _draft)
  //   debugger
  // }
  return !equal
}

const getSubSections = (section, sections) => {
  if (section.subSections) {
    for (const subSection of section.subSections) {
      sections.push(subSection)
      getSubSections(subSection, sections)
    }
  }
}

const getSections = (document) => {
  const sections = []
  for (const section of document.sections) {
    sections.push(section)
    getSubSections(section, sections)
  }
  return sections
}

export const hasStructuralChanges = (document) => {
  if (!hasChanges(document)) {
    return false
  }
  const documentSections = getSections(document)
  const draftSections = getSections(document._draft)
  return documentSections.length !== draftSections.length || draftSections.some((section, i) => section.name !== documentSections[i].name ||
    section.sectionId !== documentSections[i].sectionId
  )
}

export const isPending = (document) => document._progress && document._progress.pending

export const hasError = (document) => document._progress && document._progress.error

const findSubSection = (subSections, sectionId) => {
  for (const section of subSections) {
    if (section.sectionId === sectionId) {
      return section
    } else if (section.subSections) {
      const subSection = findSubSection(section.subSections, sectionId)
      if (subSection) {
        return subSection
      }
    }
  }
}

export const findSection = (document, sectionId) => {
  for (const section of document.sections) {
    if (section.sectionId === sectionId) {
      return section
    } else if (section.subSections) {
      const subSection = findSubSection(section.subSections, sectionId)
      if (subSection) {
        return subSection
      }
    }
  }
}

export const setSection = (document, section) => {
  const sections = document.sections || document.subSections
  for (let i = 0; i < sections.length; i++) {
    const s = sections[i]
    if (s.sectionId === section.sectionId) {
      sections[i] = section
    } else if (s.subSections) {
      setSection(s, section)
    }
  }
  return document
}
