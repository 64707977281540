export const DEFAULT_BLOCK = 'paragraph'
export const HEADER_ONE = 'heading-one'
export const HEADER_TWO = 'heading-two'
export const HEADER_THREE = 'heading-three'
export const HEADER_FOUR = 'heading-four'
export const HEADER_FIVE = 'heading-five'
export const BLOCK_QUOTE = 'block-quote'
export const NUMBERED_LIST = 'numbered-list'
export const BULLETED_LIST = 'bulleted-list'
export const LIST_ITEM = 'list-item'
