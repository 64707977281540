// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".container-fluid{margin:0;padding:0}.src-components-home-Home__container--YQAE0{margin:auto;box-sizing:border-box;min-width:1400px}\n", "",{"version":3,"sources":["C:/Gitlab-Runner/builds/josiD6fY/0/developers/comtax-admin/src/components/home/Home.scss"],"names":[],"mappings":"AAEA,iBACE,QAAS,CACT,SAAU,CACX,4CAGC,WAAW,CACX,qBAAsB,CACtB,gBAAiB","file":"Home.scss","sourcesContent":["@import '../base';\r\n\r\n:global(.container-fluid) {\r\n  margin: 0;\r\n  padding: 0;\r\n}\r\n\r\n.container {\r\n  margin:auto;\r\n  box-sizing: border-box;\r\n  min-width: 1400px;\r\n}"]}]);
// Exports
exports.locals = {
	"container": "src-components-home-Home__container--YQAE0"
};
module.exports = exports;
