import React, { PureComponent } from 'react'
import { withRouter, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { Button, ButtonToolbar, Spinner } from 'react-bootstrap'

import { loginRequest, logout, LOGIN_REQUEST } from '../../state/auth/authActions'
import { getAuth } from '../../state/auth/authReducer'
import { getPending, getError } from '../../state/progress/progressReducer'
import styles from './Login.scss'

const mapStateToProps = (state, props) => {
  return {
    loggingIn: getPending(LOGIN_REQUEST)(state),
    loginError: getError(LOGIN_REQUEST)(state),
    loggedIn: getAuth(state).isAuthenticated
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    login: (username, password) => dispatch(loginRequest(username, password)),
    logout: () => dispatch(logout())
  }
}

class Login extends PureComponent {
  constructor(props) {
    super(props)
    
    this.state = {
      username: '',
      password: '',
      submitted: false
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleChange(e) {
    const { name, value } = e.target
    this.setState({ [name]: value })
  }

  handleSubmit(e) {
    e.preventDefault()

    this.setState({ submitted: true })
    const { username, password } = this.state
    if (username && password) {
      this.props.login(username, password)
    }
  }

  render() {
    const { loggingIn, loginError, loggedIn } = this.props
    const { username = '', password = '', submitted = false } = this.state

    return <div className={styles.body}>      
      <div className={styles.container}>
        {loggedIn &&
          <Redirect to={{ pathname: '/' }} />
        }
        <div className={styles.header}>
          <h1>COMTAX ADMIN</h1>
          <div />
        </div>
        <form name='form' onSubmit={this.handleSubmit} className={styles.form}>
          <div className={'form-group' + (submitted && !username ? ' has-error' : '')}>
            <input type='text' placeholder='Username' className='form-control' name='username' value={username} onChange={this.handleChange} />
            {submitted && !username &&
              <div className={styles.help}>Username is required</div>
            }
          </div>
          <div className={'form-group' + (submitted && !password ? ' has-error' : '')}>
            <input type='password' placeholder='Password' className='form-control' name='password' value={password} onChange={this.handleChange} />
            {submitted && !password &&
              <div className={styles.help}>Password is required</div>
            }
          </div>
          <div className='form-group'>
            <ButtonToolbar>
              <Button variant='primary' onClick={this.handleSubmit} disabled={loggingIn}>
                {loggingIn ?
                  <Spinner
                    as='span'
                    animation='border'
                    size='sm'
                    role='status'
                    aria-hidden='true'
                  /> : 'Login'}
              </Button>
            </ButtonToolbar>
            {loginError &&
              <strong className={styles.error}>{loginError && loginError.message}</strong>
            }
          </div>
        </form>
      </div>
    </div>
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login))
