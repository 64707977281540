import React, { useState } from 'react'
import { Form, Row, Col, Button, InputGroup, FormControl, DropdownButton, Dropdown } from 'react-bootstrap'

import CountrySelector from './CountrySelector.jsx'

const PositionSelector = (props) => {
    const { countries } = props
    return <div>
        <Form.Group as={Row} className="mt-2">
            <Form.Label column sm={1}>
                Position:
                </Form.Label>
            <Col lg={2}>
                <Form.Control as="select" size="m">
                    <option value="initial">Initial</option>
                    <option value="intermediary">Intermediary</option>
                    <option value="final">Final</option>
                </Form.Control>
            </Col>
        </Form.Group>
        <Form.Group as={Row} className="mt-2">

            <Col offset={1} lg={3}>
                <CountrySelector onChange={
                    (data) => console.log(data)
                } selectedCountries={[]} allCountries={countries}>Previous</CountrySelector>
            </Col>
            <Col lg={3}>
                <CountrySelector onChange={
                    (data) => console.log(data)
                } selectedCountries={[]} allCountries={countries}>Current</CountrySelector>
            </Col>
            <Col lg={3}>
                <CountrySelector onChange={
                    (data) => console.log(data)
                } selectedCountries={[]} allCountries={countries}>Next</CountrySelector>
            </Col>
        </Form.Group>
    </div>
}

export default PositionSelector
