// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".src-components-accounts-AccountEditor-Status__label--11-Qs{font-weight:bold;white-space:nowrap;letter-spacing:1px;font-weight:700;text-transform:uppercase}\n", "",{"version":3,"sources":["C:/Gitlab-Runner/builds/josiD6fY/0/developers/comtax-admin/src/components/accounts/AccountEditor/Status.scss"],"names":[],"mappings":"AAAA,4DACE,gBAAiB,CACjB,kBAAmB,CACnB,kBAAmB,CACnB,eAAgB,CAChB,wBAAyB","file":"Status.scss","sourcesContent":[".label {\r\n  font-weight: bold;\r\n  white-space: nowrap;\r\n  letter-spacing: 1px;\r\n  font-weight: 700;\r\n  text-transform: uppercase;\r\n}"]}]);
// Exports
exports.locals = {
	"label": "src-components-accounts-AccountEditor-Status__label--11-Qs"
};
module.exports = exports;
