// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".src-components-subHeader-SubHeader__subHeader--O0_QS{display:inline-block;position:relative}\n", "",{"version":3,"sources":["C:/Gitlab-Runner/builds/josiD6fY/0/developers/comtax-admin/src/components/subHeader/SubHeader.scss"],"names":[],"mappings":"AAEA,sDACE,oBAAqB,CACrB,iBAAkB","file":"SubHeader.scss","sourcesContent":["@import '../_base.scss';\r\n\r\n.subHeader {\r\n  display: inline-block;\r\n  position: relative;\r\n}\r\n"]}]);
// Exports
exports.locals = {
	"subHeader": "src-components-subHeader-SubHeader__subHeader--O0_QS"
};
module.exports = exports;
