import { CREATE_DOCUMENT } from '../documents/documentActions'
import uuid from 'uuid/v4'
import moment from 'moment'

import accountSchema from '../../../data/accounts/schema.account.json'

export const createSection = (base, { tags = {}, subSections = [] } = {}) => {
  const template = {
    sectionId: uuid(),
    value: {
      object: 'value',
      document: {
        data: {
        },
        nodes: [
          {
            data: {
            },
            nodes: [
              {
                marks: [
                ],
                object: 'text',
                text: ''
              }
            ],
            object: 'block',
            type: 'paragraph'
          }
        ],
        object: 'document'
      }
    },
    html: '<p></p>',
    tags,
    subSections
  }
  if (typeof base === 'object') {
    return Object.assign({}, template, base)
  }
  return Object.assign({}, template, { name: base })
}

export function newCommentary(country) {
  return {
    type: CREATE_DOCUMENT,
    payload: {
      document: {
        type: 'commentary',
        _id: `commentary.${uuid()}`,
        countryId: country._id,
        name: country.name,
        sections: [
          createSection('Corporate income tax system',
            {
              subSections: [createSection('Group taxation')]
            }
          ),
          createSection('Tax rates',
            {
              subSections: [
                createSection('Deductible payments'),
                createSection('Capital gains taxation')
              ]
            }
          ),
          createSection('Other taxes',
            {
              subSections: [
                createSection('Stamp duty'),
                createSection('Transfer tax')
              ]
            }
          ),
          createSection('Double taxation relief',
            {
              subSections: [
                createSection('Matching credit/tax sparing')
              ]
            }
          ),
          createSection('Anti-avoidance',
            {
              tags: {
                'Anti Avoidance': 'General'
              },
              subSections: [
                createSection('CFC Legislation', { tags: { 'Anti Avoidance': 'CFC' } }),
                createSection('Thin capitalisation / Interest barrier', { tags: { 'Anti Avoidance': 'Thin Capitalisation' } }),
                createSection('Treaty shopping'),
                createSection('Transfer pricing', { tags: { 'Anti Avoidance': 'Transfer Pricing' } })
              ]
            }
          ),
          createSection('Losses',
            {
              subSections: [
                createSection('Capital losses'),
                createSection('Ownership changes')
              ]
            }
          ),
          createSection('Tax treaties',
            {
              subSections: [
                createSection('Treaties signed'),
                createSection('Treaties in effect')
              ]
            }
          ),
          createSection('Practical use of tax treaties'),
          createSection('New tax laws'),
          createSection('BEPS',
            {
              subSections: [
                createSection('Action 1 - Digital economy', { tags: { 'BEPS': 'BEPS-1' } }),
                createSection('Action 2 - Hybrid mismatches', { tags: { 'BEPS': 'BEPS-2' } }),
                createSection('Action 3 - CFC rules', { tags: { 'BEPS': 'BEPS-3' } }),
                createSection('Action 4 - Interest deductions', { tags: { 'BEPS': 'BEPS-4' } }),
                createSection('Action 5 - Patent box', { tags: { 'BEPS': 'BEPS-5' } }),
                createSection('Action 6 - Treaty abuse', { tags: { 'BEPS': 'BEPS-6' } }),
                createSection('Action 7 - PE status', { tags: { 'BEPS': 'BEPS-7' } }),
                createSection('Action 8 - TP intangibles', { tags: { 'BEPS': 'BEPS-8' } }),
                createSection('Action 9 - TP risks and capital', { tags: { 'BEPS': 'BEPS-9' } }),
                createSection('Action 10 - TP other high-risk transactions', { tags: { 'BEPS': 'BEPS-10' } }),
                createSection('Action 11 - Collect and analyse data', { tags: { 'BEPS': 'BEPS-11' } }),
                createSection('Action 12 - Multilateral instruments', { tags: { 'BEPS': 'BEPS-12' } }),
                createSection('Action 13 - CbC reporting', { tags: { 'BEPS': 'BEPS-13' } }),
                createSection('Action 14 - Dispute resolution', { tags: { 'BEPS': 'BEPS-14' } }),
                createSection('Action 15 - Multilateral instruments', { tags: { 'BEPS': 'BEPS-15' } })
              ]
            }
          )
        ]
      }
    }
  }
}

export function newPublishLog() {
  return {
    type: CREATE_DOCUMENT,
    payload: {
      document: {
        type: 'publishLog',
        _id: `publishLog.${uuid()}`,
        name: `Log`,
        published: false,
        sections: [
          createSection('Summary')
        ]
      }
    }
  }
}

function newNote(subType, extraPayload) {
  return {
    type: CREATE_DOCUMENT,
    payload: {
      document: {
        type: `note-${subType}`,
        _id: `note-${subType}.${uuid()}`,
        start_utc: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
        end_utc: '',
        name: null,
        priority: 0,
        condition: null,
        target: null,
        sections: [
          createSection('Text')
        ],
        ...extraPayload
      }
    }
  }
}

export function newResultNote() {
  return Object.assign(newNote('result',
    {
      clauses: [],
      position: '',
      previousCountries: [],
      currentCountries: [],
      nextCountries: []
    }
  ))
}

export function newAccount() {
  return {
    type: CREATE_DOCUMENT,
    payload: {
      document: {
        type: `account`,
        _id: `account.${uuid()}`,
        productKey: '',
        name: '',
        url: '',
        maxNoEnabledUsers: accountSchema.properties.maxNoEnabledUsers.default,
        enabled: accountSchema.properties.enabled.default,
        expirationDate_utc: moment().add(1, 'y').format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
        license: {
          calculator: accountSchema.properties.license.properties.calculator.default,
          countries: [],
          modules: []
        }
      }
    }
  }
}

export function newUser(accountId) {
  return {
    type: CREATE_DOCUMENT,
    payload: {
      document: {
        type: 'user',
        _id: `__temp__.${uuid()}`,
        name: '',
        email: '',
        enabled: false,
        accountId,
        roles: [],
        notified: false,        
        password: ''
      }
    }
  }
}
