import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Dropdown } from 'react-bootstrap'
import { useDrag, useDrop } from 'react-dnd'

import { DRAG_TYPE } from './TreeEditorConstants'
import styles from './TreeNode.scss'

const Toggler = ({ treeNode: { expanded }, toggle }) => <span onClick={() => toggle()}
    className={classNames({
        [styles.expand]: !expanded,
        [styles.collapse]: expanded,
    })}></span>

const Name = ({ onClick, readonly, disabled, isDragging, isHovering, drag, name, rename, createNode, removeNode }) => {    
    const [state, setState] = useState({ showDropdownToggle: false, showDropdown: false })
    const [renaming, setRenaming] = useState(name === '')
    return <span
        onClick={onClick}
        onMouseEnter={() => {            
            setState({ showDropdownToggle: !(readonly || disabled || isDragging || isHovering || renaming) })
        }}
        onMouseLeave={() => {            
            setState({ showDropdownToggle: state.showDropdown })
        }}
    >
        {renaming ?
            <input autoFocus type="text" value={name} onBlur={() => setRenaming(false)}
                onChange={(event) => {
                    rename(event.target.value)
                }}
                onKeyDown={(event) => event.keyCode === 13 && setRenaming(false)} /> :
            <span ref={drag}
                className={classNames(styles.name, { [styles.isDragged]: isDragging, [styles.isHovering]: isHovering })}
            >{name}</span>
        }
        {state.showDropdownToggle && <ActionsDropdown
            onToggle={(show) => {                
                setState({ showDropdownToggle: show, showDropdown: show })
            }}
            renameNode={() => setRenaming(true)}
            createNode={createNode} removeNode={removeNode} />}
    </span>
}

const DropDownContainer = ({ children }) => {
    return <span>{children}</span>
}

const DropdownToggle = React.forwardRef(({ children, onClick, isDragging }, ref) =>
    <span ref={ref} className={classNames(styles.dropdownToggle, { [styles.isDragged]: isDragging })}
        onClick={e => {
            e.preventDefault();
            onClick(e);
        }}>
        {children}
    </span >)

const DropdownMenu = ({ renameNode, createNode, removeNode }) => <Dropdown.Menu>
    <Dropdown.Item onClick={() => {
        renameNode()
    }} eventKey="RENAME">RENAME</Dropdown.Item>
    <Dropdown.Item onClick={() => {
        createNode('')
    }} eventKey="ADD">ADD</Dropdown.Item>
    <Dropdown.Item onClick={() => {
        removeNode()
    }} eventKey="REMOVE">REMOVE</Dropdown.Item>
</Dropdown.Menu>

const ActionsDropdown = ({ renameNode, createNode, removeNode, onToggle }) => <Dropdown onToggle={onToggle} as={DropDownContainer}>
    <Dropdown.Toggle as={DropdownToggle}>...</Dropdown.Toggle>
    <DropdownMenu renameNode={renameNode} createNode={createNode} removeNode={removeNode} />
</Dropdown>


const TreeNode = ({ node, node: { id, name, expanded },
    readonly, disabled, onClick, toggle, dropNode, renameNode, removeNode, createNode, children }) => {

    const [{ isDragging }, drag] = useDrag({
        canDrag: () => {
            const cd = !(readonly || disabled)            
            return cd
        },
        item: { type: DRAG_TYPE, id, name },
        collect: monitor => ({
            isDragging: !!monitor.isDragging(),
        }),
    })

    const [{ isHovering }, drop] = useDrop({
        accept: DRAG_TYPE,
        canDrop: () => !(readonly || disabled),
        hover: (item, monitor) => {
            if (monitor.isOver({ shallow: true }) && id !== item.id && !expanded) {
                toggle()
            }
        },
        drop: (item, monitor) => {
            if (monitor.isOver({ shallow: true })) {
                dropNode(item.id)
            }
        },
        collect: monitor => ({
            isHovering: !!monitor.isOver({ shallow: true })
        })
    })
    return <div className={classNames(styles.treeNode, { [styles.expandedTreeNode]: expanded && children.length })} ref={drop}>
        {node.children && !!node.children.length && <Toggler treeNode={node} toggle={() => !disabled && toggle()} />}
        <Name readonly={readonly}
            disabled={disabled}
            onClick={() => onClick(node.data)}
            isDragging={isDragging}
            isHovering={isHovering}
            drag={drag}
            name={name}
            rename={renameNode}
            createNode={createNode}
            removeNode={() => removeNode(node)} />
        {children}
    </div >
}

// A TreeNode contains the following:
// * id: a globally unique identifier for the node
// * level: the level of the node, 0 for the top level
// * expanded: States if the node should be expanded. Defaults to false.
// * parent: parent node
// * children: an array of child nodes. Child nodes have a level one higher than the parent
// * data: user data
TreeNode.propTypes = {
    node: PropTypes.object.isRequired,
    toggle: PropTypes.func.isRequired,
    readonly: PropTypes.bool
}

export default TreeNode
