// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".src-components-taxGuide-DocumentFactory__documentFactory--3BfS_{font-size:medium;display:block;margin:10px 0;width:100%}.src-components-taxGuide-DocumentFactory__documentFactory--3BfS_:disabled{border:1px solid #c71d1d}\n", "",{"version":3,"sources":["C:/Gitlab-Runner/builds/josiD6fY/0/developers/comtax-admin/src/components/taxGuide/DocumentFactory.scss"],"names":[],"mappings":"AAEA,iEACE,gBAAiB,CACjB,aAAc,CACd,aAAc,CACd,UAAW,CAJb,0EAMI,wBAAyB","file":"DocumentFactory.scss","sourcesContent":["@import \"../_base.scss\";\r\n\r\n.documentFactory {\r\n  font-size: medium;\r\n  display: block;\r\n  margin: 10px 0;\r\n  width: 100%;\r\n  &:disabled {\r\n    border: 1px solid #c71d1d;\r\n  }\r\n}\r\n"]}]);
// Exports
exports.locals = {
	"documentFactory": "src-components-taxGuide-DocumentFactory__documentFactory--3BfS_"
};
module.exports = exports;
