export const PREFIX = 'user'

export const PUT_USER = `${PREFIX}/put_user`
export const REMOVE_USER = `${PREFIX}/remove_user`
export const NOTIFY_USER = `${PREFIX}/notify_user`
export const ENABLE_USER = `${PREFIX}/enable_user`


export function putUser(user, delay) {
  return {
    type: PUT_USER,
    payload: { user, delay }
  }
}

export function notifyUser(user) {
  return {
    type: NOTIFY_USER,
    payload: { user }
  }
}

export function enableUser(user) {
  return {
    type: ENABLE_USER,
    payload: { user }
  }
}

export function removeUser(document) {
  return {
    type: REMOVE_USER,
    payload: { document }
  }
}