// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./img/unlocked.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./img/locked.png");
exports = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".src-components-documentEditor-structureEditor-Lock__container--2QvF3{height:32px;width:44px;display:inline-block}.src-components-documentEditor-structureEditor-Lock__lock--1Lw_B{height:100%;width:100%;background-repeat:no-repeat;cursor:pointer}.src-components-documentEditor-structureEditor-Lock__opened--1nQLG{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:left}.src-components-documentEditor-structureEditor-Lock__closed--1iNMr{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");background-position:center}\n", "",{"version":3,"sources":["C:/Gitlab-Runner/builds/josiD6fY/0/developers/comtax-admin/src/components/documentEditor/structureEditor/Lock.scss"],"names":[],"mappings":"AAAA,sEACE,WAAY,CACZ,UAAW,CACX,oBAAqB,CACtB,iEAGC,WAAY,CACZ,UAAW,CACX,2BAA4B,CAC5B,cAAe,CAChB,mEAGC,wDAA2C,CAC3C,wBAAyB,CAC1B,mEAGC,wDAAyC,CACzC,0BAA2B","file":"Lock.scss","sourcesContent":[".container {\r\n  height: 32px;\r\n  width: 44px;\r\n  display: inline-block;\r\n}\r\n\r\n.lock {\r\n  height: 100%;\r\n  width: 100%;\r\n  background-repeat: no-repeat;\r\n  cursor: pointer;\r\n}\r\n\r\n.opened {\r\n  background-image: url('./img/unlocked.png');\r\n  background-position: left;\r\n}\r\n\r\n.closed {\r\n  background-image: url('./img/locked.png');\r\n  background-position: center;\r\n}"]}]);
// Exports
exports.locals = {
	"container": "src-components-documentEditor-structureEditor-Lock__container--2QvF3",
	"lock": "src-components-documentEditor-structureEditor-Lock__lock--1Lw_B",
	"opened": "src-components-documentEditor-structureEditor-Lock__opened--1nQLG",
	"closed": "src-components-documentEditor-structureEditor-Lock__closed--1iNMr"
};
module.exports = exports;
