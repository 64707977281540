import generatePassword from 'password-generator'
import { createSelector } from 'reselect'
import cloneDeep from 'clone-deep'
import moment from 'moment'

import { SET_DRAFT } from '../documents/documentActions'
import { GENERATE_PRODUCT_KEY } from './accountActions'
import accountSchema from '../../../data/accounts/schema.account.json'

const validateName = (draft, error, others) => {
  if (Object.values(others).find((ac) => ac.name === draft.name)) {
    error.name = 'Name already taken!'
  }
  else {
    delete error.name
  }
}

const setUrl = (draft) => {
  draft.url = draft.name.replace(/[^a-z0-9_$()+/-]/gi, '_').toLowerCase()
}

const validateUrl = (draft, error, others) => {
  if (draft.url && !/^[a-z][a-z0-9_$()+/-]*$/gi.test(draft.url)) {
    error.url = 'Url can only contain the following: a-z; 0-9; _$()+/-'
  }
  else if (draft.url && Object.values(others).find((ac) => ac.url === draft.url)) {
    error.url = 'Url already taken!'
  }
  else {
    delete error.url
  }
}

const validateProcuctKey = (draft, error, others) => {
  const pattern = new RegExp(accountSchema.properties.productKey.pattern)
  if (draft.productKey && !pattern.test(draft.productKey)) {
    error.productKey = 'Product Key must have the following pattern XXXXX-XXXXX-XXXXX-XXXXX and can only contain A-Z; 0-9; -'
  }
  else if (draft.productKey && Object.values(others).find((ac) => ac.productKey === draft.productKey)) {
    error.productKey = 'ProductKey already taken!'
  }
  else {
    delete error.productKey
  }
}

const validateExpirationDate = (draft, error, others) => {
  const pattern = new RegExp(accountSchema.properties.expirationDate_utc.pattern)      
  if (draft.expirationDate_utc && !pattern.test(draft.expirationDate_utc)) {
    error.expirationDate_utc = 'Product Key must have the following pattern YYYY-MM-DDTHH:mm:ss.SSSZ'
  }  
  else if (moment(draft.expirationDate_utc).isBefore(moment()) ) {
    error.expirationDate_utc = 'Date have already expired'
  }
  else {
    delete error.expirationDate_utc
  }
}

const validateEnabled = (draft, error, others) => {  
  if (draft.enabled && moment(draft.expirationDate_utc).isBefore(moment())) {
    error.enabled = 'Cannot enable an expired account'
  }
  else {
    delete error.enabled
  }
}

const actions = {
  [SET_DRAFT](state, action) {
    const { payload: { draft } } = action
    const { _id } = draft
    const others = Object.assign({}, state['account'])
    const document = cloneDeep(others[_id])
    delete others[_id]
    if (!document._progress) {
      document._progress = {}
    }
    const { _progress: { error = {} } } = document
    delete document._progress.error

    validateName(draft, error, others)
    if (document._draft.name !== draft.name && !error.name) {
      setUrl(draft)
    }
    validateUrl(draft, error, others)
    validateProcuctKey(draft, error, others)
    validateExpirationDate(draft, error, others)
    validateEnabled(draft, error, others)

    if (Object.getOwnPropertyNames(error).length) {
      document._progress.error = error
    }
    others[_id] = document
    return Object.assign({}, state, { account: others })
  },
  [GENERATE_PRODUCT_KEY](state, action) {
    const { payload: { draft } } = action
    const { _id } = draft
    const others = Object.assign({}, state['account'])
    const document = cloneDeep(others[_id])
    const { _progress: { error = {} } } = document
    delete error.productKey
    delete others[_id]
    document._draft.productKey = generatePassword(20, false, '[A-Z0-9]').match(/.{1,5}/g).join('-')
    others[_id] = document
    return Object.assign({}, state, { account: others })
  }
}

export default function (state, action) {
  if (actions[action.type]) {
    return actions[action.type](state, action)
  } else {
    return state
  }
}
