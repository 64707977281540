// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".src-components-notes-result-Result__container--16dkX{margin-top:1em}.src-components-notes-result-Result__newButton--2Q6jv{background-color:green}\n", "",{"version":3,"sources":["C:/Gitlab-Runner/builds/josiD6fY/0/developers/comtax-admin/src/components/notes/result/Result.scss"],"names":[],"mappings":"AAIA,sDACE,cAHa,CAId,sDAGG,sBAAuB","file":"Result.scss","sourcesContent":["@import '../../_base.scss';\r\n\r\n$topMargin: 1em;\r\n\r\n.container {\r\n  margin-top: $topMargin;\r\n}\r\n\r\n.newButton {\r\n    background-color: green;\r\n}"]}]);
// Exports
exports.locals = {
	"container": "src-components-notes-result-Result__container--16dkX",
	"newButton": "src-components-notes-result-Result__newButton--2Q6jv"
};
module.exports = exports;
