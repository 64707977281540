// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".src-components-accounts-AccountEditor-License__selected--Kqi13{background-color:black !important}.src-components-accounts-AccountEditor-License__label--2ht4r{font-weight:bold;white-space:nowrap;letter-spacing:1px;font-weight:700;text-transform:uppercase}\n", "",{"version":3,"sources":["C:/Gitlab-Runner/builds/josiD6fY/0/developers/comtax-admin/src/components/accounts/AccountEditor/License.scss"],"names":[],"mappings":"AAAA,gEACE,iCAAkC,CACnC,6DAGC,gBAAiB,CACjB,kBAAmB,CACnB,kBAAmB,CACnB,eAAgB,CAChB,wBAAyB","file":"License.scss","sourcesContent":[".selected {\r\n  background-color: black !important;\r\n}\r\n\r\n.label {\r\n  font-weight: bold;\r\n  white-space: nowrap;\r\n  letter-spacing: 1px;\r\n  font-weight: 700;\r\n  text-transform: uppercase;\r\n}"]}]);
// Exports
exports.locals = {
	"selected": "src-components-accounts-AccountEditor-License__selected--Kqi13",
	"label": "src-components-accounts-AccountEditor-License__label--2ht4r"
};
module.exports = exports;
