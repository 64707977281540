import React from 'react'
import { connect } from 'react-redux'
import { Form, Row, Col, Button, InputGroup, FormControl, DropdownButton, Dropdown } from 'react-bootstrap'
import debounce from 'debounce'


import { getDocumentById, getDocuments } from '../../../state/documents/documentReducer'
import { createDraft, setDraft, discardDraft, putDocument } from '../../../state/documents/documentActions'
import countrySchema from '../../../../data/countries/schema.country.json'
import noteSchema from '../../../../data/notes/schema.note.json'
import PositionSelector from './PositionSelector.jsx'

const resultNoteSchema = Object.assign({}, noteSchema,
    {
        'properties': {
            'position': {
                'type': 'string',
                'enum': [
                    'initial',
                    'intermediate',
                    'final'
                ]
            },
            'previousCountries': {
                'type': 'array',
                'items': {
                    'type': 'string'
                }
            },
            'intermediateCountries': {
                'type': 'array',
                'items': {
                    'type': 'string'
                }
            },
            'finalCountries': {
                'type': 'array',
                'items': {
                    'type': 'string'
                }
            },
            ...noteSchema.properties
        }
    },
    {
        'required': [...noteSchema.required, 'position']
    })

const mapStateToProps = (state, props) => {
    const getCountries = getDocuments('country')
    const getDocument = getDocumentById(props.noteId)
    return {
        note: getDocument(state),
        countries: getCountries(state)
    }
}

const mapDispatchToProps = (dispatch, props) => {
    return {
        createDraft: (note) => dispatch(createDraft(note)),
        setDraft: (draft) => dispatch(setDraft(draft)),
        discardDraft: (draft) => dispatch(discardDraft(draft)),
        saveDocument: (note) => dispatch(putDocument(note, 2000))
    }
}

class ResultEditor extends React.Component {

    constructor(props) {
        super(props)
        this.setDraft = debounce((draft) => {
            props.setDraft(draft)
        }, 2000)
    }

    componentDidMount() {
        if (!this.props.note._draft) {
            this.props.createDraft(this.props.note)
        }
    }

    componentDidUpdate(prevProps) {
        if (!this.props.note._draft) {
            this.props.createDraft(this.props.note)
        }
    }

    render() {
        const { note: { _draft: draft }, countries } = this.props        

        return <Form>            
            <Form.Group as={Row}>                
                <Col lg={8}>
                    <Form.Control size="m" type="text" placeholder="Name" />
                </Col>
            </Form.Group>            
            <Form.Group as={Row} className="mt-2">
                <Form.Label column sm={1}>
                    Priority:
                </Form.Label>
                <Col lg={2}>
                    <Form.Control size="m" min="0" max="100" type="number" placeholder="0" />
                </Col>
            </Form.Group>
            <PositionSelector countries={countries} />
        </Form>
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResultEditor)
