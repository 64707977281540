// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./img/dustbin.png");
exports = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".src-components-buttons-DustbinButton__discardButton--3_Cwh{height:32px;width:32px;display:inline-block;background-repeat:no-repeat;cursor:pointer;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:center;backface-visibility:hidden}\n", "",{"version":3,"sources":["C:/Gitlab-Runner/builds/josiD6fY/0/developers/comtax-admin/src/components/buttons/DustbinButton.scss"],"names":[],"mappings":"AAAA,4DACE,WAAY,CACZ,UAAW,CACX,oBAAqB,CACrB,2BAA4B,CAC5B,cAAe,CACf,wDAA0C,CAC1C,0BAA2B,CAC3B,0BAA2B","file":"DustbinButton.scss","sourcesContent":[".discardButton {\r\n  height: 32px;\r\n  width: 32px;\r\n  display: inline-block;\r\n  background-repeat: no-repeat;\r\n  cursor: pointer;\r\n  background-image: url('./img/dustbin.png');\r\n  background-position: center;\r\n  backface-visibility: hidden;\r\n}"]}]);
// Exports
exports.locals = {
	"discardButton": "src-components-buttons-DustbinButton__discardButton--3_Cwh"
};
module.exports = exports;
