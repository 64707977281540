import React from 'react'
import { Editor } from 'slate-react'

import { Value } from 'slate'
import deepEqual from 'deep-equal'
import debounce from 'debounce'
import PropTypes from 'prop-types'

import Toolbar from './Toolbar.jsx'
import Button from '../../buttons/Button.jsx'
import ToggleButton from '../../buttons/ToggleButton.jsx'
import SaveButton from '../../buttons/SaveButton.jsx'
import DustbinButton from '../../buttons/DustbinButton.jsx'

import HeaderSelect from './HeaderSelect.jsx'
import Separator from './Separator.jsx'
import TableToolbar from './plugins/table/TableToolbar.jsx'
import TablePlugin from './plugins/table/TablePlugin.jsx'
import RTFPlugin from './plugins/rtf/RTFPlugin.jsx'
import styles from './TextEditor.scss'
import TextAlignMark from './plugins/rtf/TextAlignMark.js'
import BoldMark from './plugins/rtf/BoldMark.js'
import ItalicMark from './plugins/rtf/ItalicMark.js'
import UnderlineMark from './plugins/rtf/UnderlineMark.js'
import ColourMark from './plugins/rtf/ColourMark.js'
import { findSection, setSection, hasChanges, isPending, hasError } from '../../../utils/documentUtils'
import valueToHTML from './valueToHtml'
import renderBlock from './renderBlock'
import {
  DEFAULT_BLOCK, HEADER_ONE, HEADER_TWO, HEADER_THREE, HEADER_FOUR, HEADER_FIVE,
  BLOCK_QUOTE, NUMBERED_LIST, BULLETED_LIST, LIST_ITEM
} from './blockTypes'

const initialValue = {
  object: 'value',
  document: {
    data: {
    },
    nodes: [
      {
        data: {
        },
        nodes: [
          {
            marks: [
            ],
            object: 'text',
            text: ''
          }
        ],
        object: 'block',
        type: 'paragraph'
      }
    ],
    object: 'document'
  }
}

const plugins = [TablePlugin(), RTFPlugin()]

export default class TextEditor extends React.Component {
  constructor(props) {
    super(props)

    const { document: { _draft }, sectionId } = props

    if (_draft) {
      const section = findSection(_draft, sectionId)
      const value = Value.fromJSON(section.value)
      this.state = { value }
    } else {
      this.state = {
        value: Value.fromJSON(initialValue)
      }
    }

    this.hasMark = (mark) => {
      const { value } = this.state
      const { type, data } = mark
      return value.activeMarks.some(mark => mark.type === type && deepEqual(data, mark.data))
    }

    this.hasBlock = (type) => {
      const { value } = this.state
      return value.blocks.find(node => node.type === type)
    }

    this.ref = (editor) => {
      this.editor = editor
    }

    this.setDraft = debounce(() => {
      const draft = this.getDraft()
      this.props.setDraft(draft)
    }, 2000)

    this.onChange = (evt) => {
      const { value, operations } = evt
      this.setState({ value, operations })
      this.setDraft()
    }
  }

  getDraft() {
    const { document: { _draft }, sectionId } = this.props
    const value = this.state.value
    const html = valueToHTML(value)
    const section = Object.assign({}, findSection(_draft, sectionId), { value: value.toJSON(), html })
    return setSection(_draft, section)
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.document._draft ||
      this.props.document._id !== prevProps.document._id ||
      this.props.document._rev !== prevProps.document._rev ||
      this.props.sectionId !== prevProps.sectionId) {
      this.updateEditorState(prevProps)
    }
  }

  updateEditorState(prevProps) {
    const { document: { _draft }, sectionId } = this.props
    if (_draft) {
      const section = findSection(_draft, sectionId)
      const value = Value.fromJSON(section.value)
      this.setState({ value })
    }
  }

  render() {
    const { document, document: { _draft }, progress, readonly, discardChanges, saveChanges, hasChanges } = this.props
    const operations = this.state.operations
    let value = this.state.value

    /* Workaround for cursor bug */
    let selection
    if (operations) {
      selection = operations.get(0)["set_selection"] || operations.get(0)["newProperties"]
    }
    if(selection){
      const lastOperation = operations.last()
      const nextToLastOperation = operations.length < 2 ? undefined : operations.get(operations.size - 2)
      
      const editedEndOfText = lastOperation && nextToLastOperation && lastOperation.text && nextToLastOperation.text &&
        (lastOperation.text.length == (selection.focus.offset + 1)) && 
        (lastOperation.text.slice(0, -1) == nextToLastOperation.text)
      if (lastOperation.type == "insert_text" && editedEndOfText){
        const offset = selection.focus.offset + 1 
        selection.anchor = selection.anchor.setOffset(offset)
        selection.focus = selection.focus.setOffset(offset)
      }
      if (lastOperation.type == "remove_text" && selection) {
        const offset = lastOperation.offset
        selection.anchor = selection.anchor.setOffset(offset)
        selection.focus = selection.focus.setOffset(offset)
      }
      value = selection ? value.setSelection(selection) : value
    }

    const errorOccured = hasError(document)
    const pending = isPending(document)

    const editor = <Editor
      plugins={plugins}
      spellCheck={false}
      autoFocus
      placeholder=''
      ref={this.ref}
      value={value}
      onChange={this.onChange}
      renderBlock={this.renderBlock}
    />



    return (
      <div className={styles.container}>
        {readonly && <div className={styles.readonly} />}
        <Toolbar>
          {this.renderMarkButton(BoldMark(), styles.boldTextIcon, 'Bold')}
          {this.renderMarkButton(ItalicMark(), styles.italicTextIcon, 'Italic')}
          {this.renderMarkButton(UnderlineMark(), styles.underlineIcon, 'Underlined')}
          <Separator />
          {this.renderMarkButton(TextAlignMark('left'), styles.leftAlignedIcon, 'Left alignment')}
          {this.renderMarkButton(TextAlignMark('center'), styles.centerAlignedIcon, 'Centered alignment')}
          {this.renderMarkButton(TextAlignMark('right'), styles.rightAlignedIcon, 'Right alignment')}
          <Separator />
          <HeaderSelect
            headers={[
              { label: '', type: DEFAULT_BLOCK },
              { label: '1', type: HEADER_ONE },
              { label: '2', type: HEADER_TWO },
              { label: '3', type: HEADER_THREE },
              { label: '4', type: HEADER_FOUR },
              { label: '5', type: HEADER_FIVE }]}
            hasBlock={this.hasBlock}
            setBlock={(type) => this.editor.setBlocks(type)}
          />
          {this.renderBlockButton(BLOCK_QUOTE, styles.blockquoteIcon, 'Blockquote')}
          {this.renderBlockButton(NUMBERED_LIST, styles.orderedListIcon, 'Ordered list')}
          {this.renderBlockButton(BULLETED_LIST, styles.unorderedListIcon, 'Unordered list')}
          <Separator />
          <TableToolbar
            value={this.state.value}
            editor={this.editor}
          />
          <Separator />
          {this.renderUndoButton(styles.undoIcon, 'Undo')}
          {this.renderRedoButton(styles.redoIcon, 'Redo')}
          <br />
          {this.renderMarkButton(ColourMark('red'), styles.colorRedIcon, 'Red')}
          {this.renderMarkButton(ColourMark('orange'), styles.colorOrangeIcon, 'Orange')}
          {this.renderMarkButton(ColourMark('yellow'), styles.colorYellowIcon, 'Yellow')}
          {this.renderMarkButton(ColourMark('green'), styles.colorGreenIcon, 'Green')}
          {this.renderMarkButton(ColourMark('teal'), styles.colorTealIcon, 'Teal')}
          {this.renderMarkButton(ColourMark('blue'), styles.colorBlueIcon, 'Blue')}
          {this.renderMarkButton(ColourMark('violet'), styles.colorVioletIcon, 'Violet')}
          <div className={styles.actionControls}>
            {hasChanges && !readonly &&
              <span>
                <SaveButton
                  save={() => saveChanges()}
                  saving={pending}
                  error={errorOccured}
                />
                <DustbinButton
                  discard={() => { discardChanges(_draft) }}
                />
              </span>
            }
          </div>
          {editor}
        </Toolbar>

      </div>
    )
  }

  renderMarkButton(mark, icon, label) {
    const isActive = this.hasMark(mark)
    return (
      <ToggleButton
        label={label}
        active={isActive}
        toggle={event => this.onClickMark(event, mark)}
        icon={icon}
      />
    )
  }

  renderBlockButton(type, icon, label) {
    let isActive = this.hasBlock(type)

    if ([NUMBERED_LIST, BULLETED_LIST].includes(type)) {
      const { value: { document, blocks } } = this.state

      if (blocks.size > 0) {
        const parent = document.getParent(blocks.first().key)
        isActive = this.hasBlock(LIST_ITEM) && parent && parent.type === type
      }
    }
    return (
      <ToggleButton
        label={label}
        active={!!isActive}
        toggle={event => this.onClickBlock(event, type)}
        icon={icon}
      />
    )
  }

  renderUndoButton(icon, label) {
    return (
      <Button
        label={label}
        onClick={event => this.onClickUndo()}
        icon={icon}
      />
    )
  }

  renderRedoButton(icon, label) {
    return (
      <Button
        label={label}
        onClick={event => this.onClickRedo()}
        icon={icon}
      />
    )
  }

  renderBlock(props, editor, next) {
    const { attributes, children, node } = props
    const block = renderBlock(node, attributes, children)
    return block || next()
  }

  onClickMark(event, mark) {
    const { value } = this.state
    const { type, data } = mark
    const activeMarks = value.activeMarks.filter(mark => mark.type === type && !deepEqual(mark.data, data))
    activeMarks.forEach((mark) => this.editor.toggleMark(mark))
    this.editor.toggleMark(mark)
    event.preventDefault()
  }

  onClickBlock(event, type) {
    event.preventDefault()

    const { editor } = this
    const { value } = editor
    const { document } = value

    // Handle everything but list buttons.
    if (type !== BULLETED_LIST && type !== NUMBERED_LIST) {
      const isActive = this.hasBlock(type)
      const isList = this.hasBlock(LIST_ITEM)

      if (isList) {
        editor
          .setBlocks(isActive ? DEFAULT_BLOCK : type)
          .unwrapBlock(BULLETED_LIST)
          .unwrapBlock(NUMBERED_LIST)
      } else {
        editor.setBlocks(isActive ? DEFAULT_BLOCK : type)
      }
    } else {
      // Handle the extra wrapping required for list buttons.
      const isList = this.hasBlock(LIST_ITEM)
      const isType = value.blocks.some(block => {
        return !!document.getClosest(block.key, parent => parent.type === type)
      })

      if (isList && isType) {
        editor
          .setBlocks(DEFAULT_BLOCK)
          .unwrapBlock(BULLETED_LIST)
          .unwrapBlock(NUMBERED_LIST)
      } else if (isList) {
        editor
          .unwrapBlock(
            type === BULLETED_LIST ? NUMBERED_LIST : BULLETED_LIST
          )
          .wrapBlock(type)
      } else {
        editor.setBlocks(LIST_ITEM).wrapBlock(type)
      }
    }
  }

  onClickUndo() {
    this.editor.undo()
  }

  onClickRedo() {
    this.editor.redo()
  }
}

TextEditor.propTypes = {
  setDraft: PropTypes.func,
  discardChanges: PropTypes.func,
  document: PropTypes.object,
  sectionId: PropTypes.string,
  saveDocument: PropTypes.func,
  readonly: PropTypes.bool
}
