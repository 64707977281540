import { createSelector } from 'reselect'

import { PENDING, ERROR, DONE, CLEAR, clear } from './progressActions'

export const initialState = Object.freeze({
  pending: {},
  error: {}
})

const hashPath = (path) => path.join('/')

const actions = {
  [PENDING](state, action) {
    const { path, report } = action.payload
    const newPending = Object.assign({}, state.pending, { [hashPath(path)]: report })
    return Object.assign({}, actions[CLEAR](state, clear(path)), { pending: newPending })
  },
  [ERROR](state, action) {
    const { payload } = action
    if (payload.error instanceof Error) {
      throw new Error('Payload cannot be an instance of error!')
    }
    if (payload.error.raw && payload.error.raw instanceof Error) {
      let newRaw = ""
      try {
        const { error: { raw } } = payload
        newRaw = raw.toString() + "\n\n" + raw.stack.toString()
      } catch (e) {
        newRaw = e;
      }
      payload.error.raw = newRaw
    }
    const newError = Object.assign({}, state.error, { [hashPath(payload.path)]: payload.error })
    return Object.assign({}, actions[CLEAR](state, clear(payload.path)), { error: newError })
  },
  [DONE](state, action) {
    const { path } = action.payload
    const newPending = Object.assign({}, state.pending)
    delete newPending[hashPath(path)]
    return Object.assign({}, state, { pending: newPending })
  },
  [CLEAR](state, action) {
    const { payload: { path } } = action
    const { pending, error } = state
    const newPending = Object.assign({}, pending)
    const newError = Object.assign({}, error)
    const hash = hashPath(path)
    delete newPending[hash]
    delete newError[hash]
    return { pending: newPending, error: newError }
  }
}

export default function (state = initialState, action) {
  if (actions[action.type]) {
    return actions[action.type](state, action)
  } else {
    return state
  }
}

const getProgress = (state) => state.progress

export const getPending = (...path) => createSelector(
  getProgress,
  (progress) => progress.pending[hashPath(path)]
)

export const getError = (...path) => createSelector(
  getProgress,
  (progress) => progress.error[hashPath(path)]
)
