import { take, call, put, fork, cancel, cancelled, select } from 'redux-saga/effects'

import { ERROR } from '../progress/progressActions'
import progressSaga from '../progress/progressSaga'
import { loginResponse, logout, LOGIN_REQUEST, LOGOUT } from './authActions'
import { getAuth } from './authReducer'
import config from '../../utils/config'
import { loginError, LOGIN_ERROR } from '../../utils/error'
import fetchHelper from '../../utils/fetchHelper'

export const sagas = {
  [LOGIN_REQUEST]: progressSaga(() => [LOGIN_REQUEST],
    function* (action) {
      const { couchUrl } = config
      const { username, password } = action.payload
      try {
        const session = yield call(fetchHelper, `${couchUrl}/_session`,
          {
            body: { name: username, password },
            method: 'POST',
            credentials: 'include'
          })
        if (session.ok && session.roles.includes('_admin')) {
          yield put(loginResponse(session.name, session.roles))
        } else if (!session.ok) {
          throw loginError(session.statusText)
        }
      } catch (e) {
        throw loginError('Invalid user and/or password', e)
      } finally {
        if (yield cancelled()) {
          throw loginError('Login cancelled')
        }
      }
    }),
  [LOGOUT]: progressSaga(() => [LOGOUT],
    function* () {
      const { couchUrl } = config
      yield call(fetchHelper, `${couchUrl}/_session`,
        {
          method: 'DELETE'
        })
    }),
  [ERROR]: function* (action) {
    const { error } = action.payload
    if (error.type === LOGIN_ERROR) {
      yield put(logout())
    }
  }
}

export default function* loginSaga() {
  while (true) {
    const action = yield take([LOGIN_REQUEST, LOGOUT])
    const auth = yield select(getAuth)
    if (action.type === LOGIN_REQUEST && !auth.isAuthenticated) {
      var loginTask = yield fork(sagas[LOGIN_REQUEST], action)
    } else if (action.type === LOGOUT) {
      if (typeof loginTask !== 'undefined') {
        yield cancel(loginTask)
      }
      yield call(sagas[LOGOUT], action)
    }    
  }
}
