import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { Route } from 'react-router-dom'

import SubHeader from '../subHeader/SubHeader.jsx'
import SubHeaderLink from '../subHeader/SubHeaderLink.jsx'
import OngoingPublication from './OngoingPublication.jsx'
import Archive from './Archive.jsx'
import styles from './Publish.scss'

const Publish = (props) => {
    return <div className={styles.container}>       
        <Row noGutters={true}>
            <Col>
                <SubHeader>
                    <SubHeaderLink to='/publish/ongoing'>ONGOING</SubHeaderLink>
                    <SubHeaderLink to='/publish/archive'>ARCHIVE</SubHeaderLink>
                </SubHeader>
            </Col>
        </Row>
        <Row noGutters={true}>
            <Col>
                <Route path="/publish/ongoing"
                    render={() => <OngoingPublication />} />            
                <Route path="/publish/archive"
                    render={() => <Archive />} />
            </Col>
        </Row>
    </div>
}


export default Publish
