import React from 'react'
import classNames from 'classnames'

import UpdateTable from './UpdateTable.jsx'
import LoginTable from './LoginTable.jsx'
import FullScreenOverlay from '../FullScreenOverlay.jsx'
import styles from './AccountStatistics.scss'

const AccountStatistics = (props) => {
  const { account, refresh, refreshing, statistics } = props  
  const { update, login } = statistics
  const name = account ? account.name :
    update && update[0] && update[0].accountName
  return <div>
    {
      refreshing ?
        <FullScreenOverlay /> :
        <div>
          <h1 className={classNames(styles.mainHeading, styles.spacer)}>{name}</h1>
          <div className={styles.spacer}>
            <h2 className={styles.subHeading}>{`System updates`}</h2>
            <UpdateTable statistics={update} />
          </div>
          <div className={styles.spacer}>
            <h2 className={styles.subHeading}>{`Logins`}</h2>
            <LoginTable statistics={login} />
          </div>

        </div>

    }
  </div>
}

export default AccountStatistics