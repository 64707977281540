// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".src-components-accounts-AccountTable__sortableHeader--LdJMu{cursor:pointer}.src-components-accounts-AccountTable__sortableHeader--LdJMu:hover{text-decoration:underline}.src-components-accounts-AccountTable__row--3WyH0{cursor:pointer}\n", "",{"version":3,"sources":["C:/Gitlab-Runner/builds/josiD6fY/0/developers/comtax-admin/src/components/accounts/AccountTable.scss"],"names":[],"mappings":"AAAA,6DACE,cAAe,CADjB,mEAGI,yBAA0B,CAC3B,kDAID,cAAe","file":"AccountTable.scss","sourcesContent":[".sortableHeader {\r\n  cursor: pointer;\r\n  &:hover {\r\n    text-decoration: underline;\r\n  }\r\n}\r\n\r\n.row {\r\n  cursor: pointer;  \r\n}"]}]);
// Exports
exports.locals = {
	"sortableHeader": "src-components-accounts-AccountTable__sortableHeader--LdJMu",
	"row": "src-components-accounts-AccountTable__row--3WyH0"
};
module.exports = exports;
