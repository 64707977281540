// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".src-components-taxGuide-commentary-Commentary__container--1Jed1{margin-top:1em}.src-components-taxGuide-commentary-Commentary__header--ddR8g{text-align:center}\n", "",{"version":3,"sources":["C:/Gitlab-Runner/builds/josiD6fY/0/developers/comtax-admin/src/components/taxGuide/commentary/Commentary.scss"],"names":[],"mappings":"AAIA,iEACE,cAHa,CAId,8DAGC,iBAAkB","file":"Commentary.scss","sourcesContent":["@import '../../_base.scss';\r\n\r\n$topMargin: 1em;\r\n\r\n.container {\r\n  margin-top: $topMargin;\r\n}\r\n\r\n.header {\r\n  text-align: center;\r\n}"]}]);
// Exports
exports.locals = {
	"container": "src-components-taxGuide-commentary-Commentary__container--1Jed1",
	"header": "src-components-taxGuide-commentary-Commentary__header--ddR8g"
};
module.exports = exports;
