import React from 'react'
import { withRouter, Route } from 'react-router-dom'
import { connect } from 'react-redux'
import { Row, Col, ListGroup } from 'react-bootstrap'
import moment from 'moment'

import { getDocuments } from '../../state/documents/documentReducer'
import DocumentEditor from '../documentEditor/DocumentEditor.jsx'
import styles from './Archive.scss'

const getArchivedPublications = getDocuments('publishLog')

const mapStateToProps = (state, props) => {
    return {
        logs: getArchivedPublications(state)
    }
}

const mapDispatchToProps = (dispatch, props) => ({})

const Archive = (props) => {
    const { logs, history, location } = props
    
    return <div className={styles.container}>
        <Row>
            <Col lg={{ span: 2 }}>
                <div className={styles.listGroupContainer}>
                    <ListGroup className={styles.listGroup} variant="flush">
                        {logs.filter((log) => log.publishDate)
                            .sort((lhs, rhs) => rhs.publishDate.localeCompare(lhs.publishDate))
                            .map((log) => <ListGroup.Item active={location.pathname === `/publish/archive/${log._id}`} action onClick={(e) => {
                                e.preventDefault()
                                history.push(`/publish/archive/${log._id}`)
                            }} key={log._id}>
                                {moment(log.publishDate).format('YYYY-MM-DD HH:mm:ss')}
                            </ListGroup.Item>)}
                    </ListGroup>
                </div>
            </Col>
            <Col lg={{ span: 10 }}>
                <div className={styles.container}>
                    <Route path="/publish/archive/:id" render={({ match }) => <DocumentEditor documentId={match.params.id} />} />
                </div>
            </Col>
        </Row>
    </div>
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Archive))