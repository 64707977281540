import 'bootstrap/dist/css/bootstrap.min.css'
import './components/_global.scss'

import { hot } from 'react-hot-loader/root'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import { Provider } from 'react-redux'
import createSagaMiddleware from 'redux-saga'
//import { persistStore, persistReducer } from 'redux-persist'
//import storage from 'redux-persist/lib/storage'
//import { PersistGate } from 'redux-persist/integration/react'
import PouchDB from 'pouchdb-browser'

import './utils/config'
import PrivateRoute from './components/PrivateRoute.jsx'
import Home from './components/home/Home.jsx'
import Login from './components/login/Login.jsx'
import authReducer from './state/auth/authReducer'
import authSaga from './state/auth/authSaga'
import publishSaga from './state/publish/publishSaga'
import accountSaga from './state/accounts/accountSaga'
import userSaga from './state/users/userSaga'
import progressReducer from './state/progress/progressReducer'
import documentReducer from './state/documents/documentReducer'
import accountReducer from './state/accounts/accountReducer'
import userReducer from './state/users/userReducer'
import { SET_DOCUMENTS } from './state/documents/documentActions'

import syncSaga from './state/sync/syncSaga'
import documentSaga from './state/documents/documentSaga'
import statisticsReducer from './state/statistics/statisticsReducer'
import statisticsSaga from './state/statistics/statisticsSaga'

const actionSanitizer = (action) => (action.type === SET_DOCUMENTS && action.payload.documents ?
  { ...action, payload: action.payload.documents.map((doc) => doc._id) } : action
)

const keepTheseDocs = ['account', 'user']
const documentsStateSanitizer = (documents) => {
  const result = Object.keys(documents).reduce((sanitized, key) => {
    if (!keepTheseDocs.includes(key)) {
      sanitized[key] = Object.keys(documents[key]).length
    }
    return sanitized
  }, Object.assign({}, documents))
  return result
}
const stateSanitizer = (state) => state.documents ? { ...state, documents: documentsStateSanitizer(state.documents) } : state
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    actionSanitizer,
    stateSanitizer
  }) : compose

const sagaMiddleware = createSagaMiddleware()
const reducer = combineReducers({
  auth: authReducer,
  progress: progressReducer,
  statistics: statisticsReducer,
  documents: documentReducer({
    account: accountReducer,
    user: userReducer
  })
})
// const persistedReducer = persistReducer({ key: 'root', storage }, reducer)
const store = createStore(
  // persistedReducer,
  reducer,
  composeEnhancer(
    applyMiddleware(
      sagaMiddleware
    )
  )
)

// const persistor = persistStore(store)

const db = new PouchDB('comtax-admin')
sagaMiddleware.run(authSaga)
sagaMiddleware.run(syncSaga, db)
sagaMiddleware.run(documentSaga, db)
sagaMiddleware.run(publishSaga, db)
sagaMiddleware.run(accountSaga, db)
sagaMiddleware.run(userSaga, db)
sagaMiddleware.run(statisticsSaga, db)

const Root = hot(() =>
  <Provider store={store}>
    {/* <PersistGate loading={null} persistor={persistor}> */}    
    <Router>
      <Switch>
        <Route path="/login" component={Login} />
        <PrivateRoute path="/" component={Home} />
      </Switch>
    </Router>    
    {/* </PersistGate> */}
  </Provider>)

ReactDOM.render(<Root />,
  document.getElementById('root')
)
