import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { Route } from 'react-router-dom'

import SubHeader from '../subHeader/SubHeader.jsx'
import SubHeaderLink from '../subHeader/SubHeaderLink.jsx'
import Result from './result/Result.jsx'
import styles from './Notes.scss'

const Notes = (props) => {
    return <div className={styles.container}>       
        <Row noGutters={true}>
            <Col>
                <SubHeader>
                    <SubHeaderLink to='/notes/result'>RESULT</SubHeaderLink>
                    <SubHeaderLink to='/notes/tax_rates'>TAX RATES</SubHeaderLink>                    
                    <SubHeaderLink to='/notes/relational_tax_rates'>RELATIONAL TAX RATES</SubHeaderLink>                    
                </SubHeader>
            </Col>
        </Row>
        <Row noGutters={true}>
            <Col>
                <Route path="/notes/result"
                    render={() => <Result />} />            
            </Col>
        </Row>
    </div>
}


export default Notes
