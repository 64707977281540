import { takeEvery, call, race, delay } from 'redux-saga/effects'
import progressSaga from '../progress/progressSaga'
import PouchDB from 'pouchdb-browser'

import { PUT_DOCUMENT, REMOVE_DOCUMENT } from './documentActions'
import config from '../../utils/config'
import { timeoutError } from '../../utils/error'

export const sagas = {
  [PUT_DOCUMENT]: progressSaga((action) => [PUT_DOCUMENT, action.payload.document.type, action.payload.document._id],
    function* (db, action) {
      const login = yield call([db, db.get], config.localLoginDocId)
      const remoteDb = new PouchDB(`${login.couchUrl}/${login.db}`,
        {
          skip_setup: true,
          auto_compaction: true,
          fetch: (url, opts) => {
            opts.credentials = 'include'
            return PouchDB.fetch(url, opts)
          }
        })
      const { document, delay: ms } = action.payload
      delete document._draft
      delete document._progress
      if (ms) {
        yield delay(ms)
      }
      const { timeout } = yield race({
        response: call([remoteDb, remoteDb.put], document),
        timeout: delay(30000)
      })
      if (timeout) {
        throw timeoutError('Operation timed out', new Error())
      }
    }),
  [REMOVE_DOCUMENT]: progressSaga((action) => [REMOVE_DOCUMENT, action.payload.document.type, action.payload.document._id],
    function* (db, action) {
      const login = yield call([db, db.get], config.localLoginDocId)
      const remoteDb = new PouchDB(`${login.couchUrl}/${login.db}`,
        {
          skip_setup: true,
          auto_compaction: true,
          fetch: (url, opts) => {
            opts.credentials = 'include'
            return PouchDB.fetch(url, opts)
          }
        })
      const { _id: id, _rev: rev } = action.payload.document
      if (rev) {
        let doc
        try {
          doc = yield call([remoteDb, remoteDb.get], id)
        } catch (e) {
          if (e.status !== 404) {
            throw e
          }
        }
        if (doc) {
          yield call([remoteDb, remoteDb.remove], doc._id, doc._rev)
        }
      }
    })
}
export default function* documentSaga(db) {
  yield takeEvery(PUT_DOCUMENT, sagas[PUT_DOCUMENT], db)
  yield takeEvery(REMOVE_DOCUMENT, sagas[REMOVE_DOCUMENT], db)  
}
