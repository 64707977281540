// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".src-components-notes-Notes__container--26JDZ{padding-top:1em}\n", "",{"version":3,"sources":["C:/Gitlab-Runner/builds/josiD6fY/0/developers/comtax-admin/src/components/notes/Notes.scss"],"names":[],"mappings":"AAAA,8CACI,eAAgB","file":"Notes.scss","sourcesContent":[".container {\r\n    padding-top: 1em;\r\n}\r\n\r\n"]}]);
// Exports
exports.locals = {
	"container": "src-components-notes-Notes__container--26JDZ"
};
module.exports = exports;
