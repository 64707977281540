import {SET_STATISTICS} from './statisticsActions'

import { createSelector } from 'reselect'


// { (accountId, statistics) }
export const initialState = Object.freeze({  
})

const actions = {
  [SET_STATISTICS](state, action){
    const { payload: {accountId, statistics} } = action    
    return Object.assign({}, state, { [accountId]: statistics })
  }
}

export default function (state = initialState, action) {
  if (actions[action.type]) {
    return actions[action.type](state, action)
  } else {
    return state
  }
}


export const getStatistics = createSelector(
    (state) => state.statistics,
    (statistics) => statistics)
