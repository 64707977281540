// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".src-components-accounts-AccountEditor-AccountEditor__form--2hceO label{text-transform:uppercase;letter-spacing:1px;font-weight:700}\n", "",{"version":3,"sources":["C:/Gitlab-Runner/builds/josiD6fY/0/developers/comtax-admin/src/components/accounts/AccountEditor/AccountEditor.scss"],"names":[],"mappings":"AAAA,wEAEI,wBAAyB,CACzB,kBAAmB,CACnB,eAAgB","file":"AccountEditor.scss","sourcesContent":[".form {\r\n  label {\r\n    text-transform: uppercase;\r\n    letter-spacing: 1px;\r\n    font-weight: 700;\r\n  }\r\n}"]}]);
// Exports
exports.locals = {
	"form": "src-components-accounts-AccountEditor-AccountEditor__form--2hceO"
};
module.exports = exports;
