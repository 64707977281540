import { takeEvery, call, race, delay, put, select } from 'redux-saga/effects'
import progressSaga from '../progress/progressSaga'
import PouchDB from 'pouchdb-browser'
import generatePassword from 'password-generator'

import { PUT_USER, REMOVE_USER, NOTIFY_USER, ENABLE_USER } from './userActions'
import { discardDocument } from '../documents/documentActions'
import { getDocumentById } from '../documents/documentReducer'
import config from '../../utils/config'
import fetchHelper from '../../utils/fetchHelper'
import { timeoutError } from '../../utils/error'


const newUser = function* (account, user) {
  const { _draft: draft } = user  
  if (draft._id.startsWith('__temp__')) {
    yield put(discardDocument(draft))
    draft.email = draft.name
    draft._id = `org.couchdb.user:${draft.name}`
  }

  const password = generatePassword()
  draft.password = password  
  draft.db = account.url
}

export const sagas = {
  [PUT_USER]: progressSaga((action) => [PUT_USER, action.payload.user.accountId],
    function* (db, action) {           
      const { couchUrl } = yield call([db, db.get], config.localLoginDocId)

      const { user, user: { _draft: draft }, delay: ms } = action.payload
      const account = yield select(getDocumentById(draft.accountId))
      if (ms) {
        yield delay(ms)
      }

      if (!draft._rev) {
        yield* newUser(account, user)
      }
      
      draft.enabled = account._draft.enabled
      draft.roles = [...account._draft.license.modules]
      if (account.license.calculator) {
        draft.roles.push(account._draft.license.calculator)
      }

      const security = yield call(fetchHelper, `${couchUrl}/${account.url}/_security`, { method: 'GET' })
      const i = security.members.names.indexOf(draft.name)
      if (draft.enabled && i < 0) {
        security.members.names.push(draft.name)
      } else if (!draft.enabled && i >= 0) {
        security.members.names.splice(security.members.names.indexOf(draft.name), 1)
      }
      yield call(fetchHelper, `${couchUrl}/${account.url}/_security`, { method: 'PUT', body: security })

      const { timeout } = yield race({
        response: call(fetchHelper, `${couchUrl}/_users/${draft._id}`, { method: 'PUT', body: draft }),
        timeout: delay(30000)
      })
      if (timeout) {
        throw timeoutError('Operation timed out', new Error())
      }
    }),
  [REMOVE_USER]: progressSaga((action) => [REMOVE_USER, action.payload.document.type, action.payload.document._id],
    function* (db, action) {      
      const login = yield call([db, db.get], config.localLoginDocId)
      const remoteDb = new PouchDB(`${login.couchUrl}/_users`,
        {
          skip_setup: true,
          auto_compaction: true,
          fetch: (url, opts) => {
            opts.credentials = 'include'
            return PouchDB.fetch(url, opts)
          }
        })
      const { _id: id, _rev: rev } = action.payload.document
      if (rev) {
        let doc
        try {
          doc = yield call([remoteDb, remoteDb.get], id)
        } catch (e) {
          if (e.status !== 404) {
            throw e
          }
        }
        if (doc) {
          yield call([remoteDb, remoteDb.remove], doc._id, doc._rev)
        }
      }
    }),
  [NOTIFY_USER]: progressSaga((action) => [NOTIFY_USER, action.payload.user.accountId, action.payload.user._id],
    function* (db, action) {
      const { user } = action.payload
      const { couchUrl } = yield call([db, db.get], config.localLoginDocId)
      const password = generatePassword()

      const updatePassword = function* (password) {
        user.notified = true
        yield call(fetchHelper, `${couchUrl}/_users/${user._id}`,
          { method: 'PUT', body: Object.assign({}, user, { password }) })
      }

      yield call(updatePassword, password)
      alert(password)
      //const notification = yield call(createNotification, userDocument, password)
      //yield call(sagaFetch, `${dataGatewayUrl}/account/${accountId}/user/${userDocument._id}/notify`, { method: 'POST', body: notification })
    }),
  [ENABLE_USER]: progressSaga((action) => [ENABLE_USER, action.payload.user.userId],
    function* (db, action) {
      const { couchUrl } = yield call([db, db.get], config.localLoginDocId)
      const { payload: { user: { _draft: draft } } } = action

      const account = yield select(getDocumentById(draft.accountId))

      const security = yield call(fetchHelper, `${couchUrl}/${account.url}/_security`, { method: 'GET' })
      const i = security.members.names.indexOf(draft.name)
      if (draft.enabled && i < 0) {
        security.members.names.push(draft.name)
      } else if (!draft.enabled && i >= 0) {
        security.members.names.splice(security.members.names.indexOf(draft.name), 1)
      }
      yield call(fetchHelper, `${couchUrl}/${account.url}/_security`, { method: 'PUT', body: security })

      const { timeout } = yield race({
        response: call(fetchHelper, `${couchUrl}/_users/${draft._id}`, { method: 'PUT', body: draft }),
        timeout: delay(30000)
      })

      if (timeout) {
        throw timeoutError('Operation timed out', new Error())
      }
    }),
}
export default function* documentSaga(db) {
  yield takeEvery(PUT_USER, sagas[PUT_USER], db)
  yield takeEvery(REMOVE_USER, sagas[REMOVE_USER], db)
  yield takeEvery(NOTIFY_USER, sagas[NOTIFY_USER], db)
  yield takeEvery(ENABLE_USER, sagas[ENABLE_USER], db)
}
