const prefix = 'account'
export const GENERATE_PRODUCT_KEY = `${prefix}/generate_product_key`
export const PUT_ACCOUNT = `${prefix}/put_account`
export const DELETE_ACCOUNT = `${prefix}/delete_account`


export function generateProductKey(draft) {
  return {
    type: GENERATE_PRODUCT_KEY,
    payload: {
      draft
    }
  }
}

export function putAccount(draft, username, password) {
  return {
    type: PUT_ACCOUNT,
    payload: { draft, username, password }
  }
}

export function deleteAccount(document) {
  return {
    type: DELETE_ACCOUNT,
    payload: { document }
  }
}
