import { LOGIN_RESPONSE, LOGOUT } from './authActions'

export const initialState = Object.freeze({
  isAuthenticated: false,
  username: '',
  roles: []
})

const actions = {
  [LOGIN_RESPONSE](state, action) {
    return Object.assign({}, action.payload, { isAuthenticated: true })
  },
  [LOGOUT](state, action) {
    return Object.assign({}, initialState, { isAuthenticated: false })
  }
}

export default function (state = initialState, action) {
  if (actions[action.type]) {
    return actions[action.type](state, action)
  } else {
    return state
  }
}

export const getAuth = (state) => state.auth
