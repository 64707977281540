import { createSelector } from 'reselect'
import cloneDeep from 'clone-deep'

import { SET_DOCUMENT, SET_DOCUMENTS, CREATE_DOCUMENT, CREATE_DRAFT, SET_DRAFT, DISCARD_DRAFT, DISCARD_DOCUMENT, REMOVE_DOCUMENT, PUT_DOCUMENT, setDocument } from './documentActions'
import { PENDING, ERROR, DONE, CLEAR } from '../progress/progressActions'

const initialState = Object.freeze({})

const actions = {
  [SET_DOCUMENTS](state, action) {
    const { documents } = action.payload
    return documents.reduce((previous, current) => actions[SET_DOCUMENT](previous, setDocument(current)), state)
  },
  [SET_DOCUMENT](state, action) {
    const { document } = action.payload
    const { type, _id } = document
    if (!state[type]) {
      return Object.assign({}, state, { [type]: { [_id]: document } })
    }
    const documents = { ...state[type] }
    documents[_id] = document
    return Object.assign({}, state, { [type]: documents })
  },  
  
  [CREATE_DOCUMENT](state, action){
    let nextState = actions[SET_DOCUMENT](state, action)
    return actions[CREATE_DRAFT](nextState, action)
  },
  [DISCARD_DOCUMENT](state, action) {
    const { document } = action.payload
    const { type, _id } = document
    const documents = { ...state[type] }
    delete documents[_id]        
    return Object.assign({}, state, { [type]: documents })
  },
  [CREATE_DRAFT](state, action) {
    const { type, _id: id } = action.payload.document
    const document = { ...state[type][id] }
    delete document._draft
    delete document._progress
    document._draft = cloneDeep(document)
    const documents = { ...state[type] }
    documents[id] = document
    return Object.assign({}, state, { [type]: documents })
  },
  [DISCARD_DRAFT](state, action) {
    const { draft } = action.payload
    const { type, _id } = draft
    const documents = { ...state[type] }
    const document = { ...documents[_id] }
    delete document._draft
    delete document._progress
    documents[_id] = document
    return Object.assign({}, state, { [type]: documents })
  },
  [SET_DRAFT](state, action) {
    const { draft } = action.payload
    const { type, _id } = draft
    const documents = { ...state[type] }
    const document = { ...documents[_id] }
    document._draft = draft
    documents[_id] = document
    return Object.assign({}, state, { [type]: documents })
  },
  [PENDING](state, action) {
    const { path, report } = action.payload
    const [root, type, id] = path
    if (root !== PUT_DOCUMENT) {
      return state
    }
    const document = { ...state[type][id] }
    document._progress = {}
    document._progress.pending = report
    const documents = { ...state[type] }
    documents[id] = document
    return Object.assign({}, state, { [type]: documents })
  },
  [ERROR](state, action) {
    const { path, error } = action.payload
    const [root, type, id] = path
    if (root !== PUT_DOCUMENT) {
      return state
    }
    const document = { ...state[type][id] }
    document._progress = {}
    document._progress.error = error
    const documents = { ...state[type] }
    documents[id] = document
    return Object.assign({}, state, { [type]: documents })
  },
  [DONE](state, action) {
    const { path } = action.payload
    const [root, type, id] = path
    if (root !== PUT_DOCUMENT) {
      return state
    }
    const document = { ...state[type][id] }
    document._progress = { ...document._progress }
    delete document._progress.pending
    const documents = { ...state[type] }
    documents[id] = document
    return Object.assign({}, state, { [type]: documents })
  },
  [CLEAR](state, action) {
    const { path } = action.payload
    const [root, type, id] = path
    if (root !== PUT_DOCUMENT) {
      return state
    }
    const document = { ...state[type][id] }
    delete document._progress
    const documents = { ...state[type] }
    documents[id] = document
    return Object.assign({}, state, { [type]: documents })
  },
  [REMOVE_DOCUMENT](state, action) {
    const { document: { type, _id: id } } = action.payload
    const nextState = { ...state }
    const documentsOfType = { ...nextState[type] }
    delete documentsOfType[id]
    if (Object.keys(documentsOfType).length === 0) {
      delete nextState[type]
    } else {
      nextState[type] = documentsOfType
    }
    return nextState
  }
}

export default function (nestedReducers = {}) {
  return function documentReducer(state = initialState, action = { type: null, payload: { type: null } }) {
    const { type } = action
    let nextState = state

    const documentType = action.payload ?
      (
        action.payload.type ||
        (action.payload.document && action.payload.document.type) ||
        (action.payload.draft && action.payload.draft.type)
      ) : null
    if (nestedReducers[documentType]) {
      nextState = nestedReducers[documentType](nextState, action)
    }

    if (actions[type]) {
      return actions[type](nextState, action)
    }
    return nextState
  }
}

export const getDocument = (type, id) => (state, props) => {
  return (state.documents[type] && state.documents[type][id])
}

export const getDocuments = (type, ids) => createSelector(
  (state, props) => {
    return state.documents[type]
  },
  (docs) => {
    const arr = []
    if (ids) {
      for (const id of ids) {
        if (docs[id]) {
          arr.push(docs[id])
        }
      }
      return arr
    } else {
      return docs ? Object.values(docs) : []
    }
  }
)

export const getDocumentById = (id) => (state, props) => {
  let doc = null
  const types = Object.values(state.documents)
  let i = 0
  let type = types[i]
  while (!doc && i < types.length) {
    doc = type[id]
    type = types[++i]
  }
  return doc
}

export const getMissingForCountry = (type) => {
  const getDocumentsOfType = getDocuments(type)
  const getCountryDocuments = getDocuments('country')
  return createSelector(
    getDocumentsOfType,
    getCountryDocuments,
    (existingDocs = [], countries = []) => {
      const existingDocCountries = existingDocs.map((doc) => doc.countryId)
      return countries
        .filter((country) => !existingDocCountries.includes(country._id))
        .map((country) => ({ name: country.name, value: country._id }))
    })
}

export const getCountry = (id) => createSelector(
  getDocumentById(id),
  getDocuments('country'),
  (doc, countries) => {
    return countries.find((country) => country._id === doc.countryId)
  }
)

export const getUnpublishedLog = createSelector(
  getDocuments('publishLog'),
  (docs) => {
    return docs.find((doc) => !doc.published)
  }
)
