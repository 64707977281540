// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".src-components-taxGuide-TaxGuide__container--3Qnv4{padding-top:1em;width:100%}\n", "",{"version":3,"sources":["C:/Gitlab-Runner/builds/josiD6fY/0/developers/comtax-admin/src/components/taxGuide/TaxGuide.scss"],"names":[],"mappings":"AAAA,oDACE,eAAgB,CAChB,UAAW","file":"TaxGuide.scss","sourcesContent":[".container { \r\n  padding-top: 1em;\r\n  width: 100%;\r\n}"]}]);
// Exports
exports.locals = {
	"container": "src-components-taxGuide-TaxGuide__container--3Qnv4"
};
module.exports = exports;
