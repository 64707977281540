import React from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import { withRouter, Route } from 'react-router-dom'
import { connect } from 'react-redux'

import { newResultNote } from '../../../state/documents/documentFactoryActions'
import { hasChanges } from '../../../utils/documentUtils'
import { getDocumentById } from '../../../state/documents/documentReducer'
import ResultEditor from './ResultEditor.jsx'
import styles from './Result.scss'

const mapDispatchToProps = (dispatch, props) => {
    return {
        push: (url) => props.history.push(url),
        newNote: () => dispatch(newResultNote())
    }
}

const mapStateToProps = (state, props) => {
    return {
        disabled: props.documentId && hasChanges(getDocumentById(props.documentId)(state))
    }
}

class Result extends React.Component {
    constructor() {
        super()
        this.state = {
            selected: ''
        }
    }

    render() {
        const { newNote, push } = this.props;
        return <div className={styles.container}>
            <Row>
                <Col lg={{ span: 1 }}>
                    <Button variant="primary" block onClick={() => {
                        const note = newNote()                        
                        push(`/notes/result/${note.payload.document._id}`)
                    }}>NEW</Button>
                </Col>
                <Col lg={{ span: 11 }}>
                    <Route path="/notes/result/:id" render={({ match }) => {                        
                        return <ResultEditor noteId={match.params.id} />
                    }} />
                </Col>
            </Row>
        </div>
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Result))
