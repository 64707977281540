import { takeEvery, call, take, race, delay, put, select } from 'redux-saga/effects'
import PouchDB from 'pouchdb-browser'

import progressSaga from '../progress/progressSaga'
import { DONE } from '../progress/progressActions'
import fetchHelper from '../../utils/fetchHelper'
import { PUT_ACCOUNT, DELETE_ACCOUNT } from './accountActions'
import { putDocument } from '../documents/documentActions'
import { getAccountUsers } from '../users/userReducer'
import { putUser } from '../users/userActions'
import config from '../../utils/config'
import { timeoutError, invalidAdminCredentials } from '../../utils/error'


const updateAccount = function*(baseUrl, account){  
  const masterToAccountReplicatorDoc = yield call(fetchHelper, `${baseUrl}/_replicator/${`masterTo_${account._id}`}`, { method: 'GET' })
  const createReplicationSelector = () => {

    const selector = {
      '$or': [
        {
          '_id': `${account._id}`
        },
        {
          '$and': [
            {
              'type': 'country'
            },
            {
              '_id': {
                '$in': account.license.countries
              }
            }
          ]
        },
        {
          'type': 'taxCalendar'
        },
        {
          '$and': [
            {
              'type': 'taxRelations'
            },
            {
              'countryId': {
                '$in': account.license.countries
              }
            }
          ]
        },
        {
          '$and': [
            {
              'type': 'taxRates'
            },
            {
              'countryId': {
                '$in': account.license.countries
              }
            }
          ]
        },
        ,
        {
          '$and': [
            {
              'type': 'commentary'
            },
            {
              'countryId': {
                '$in': account.license.countries
              }
            }
          ]
        },
        {
          'type': 'meta'
        },
        {
          'type': 'futureTaxRates'
        },
        {
          '_id': {
            '$in': [
              "_design/account",	
              "_design/commentary",	
              "_design/continent",	
              "_design/country",	
              "_design/dac6",	
              "_design/futureTaxRates",
              "_design/log",	
              "_design/meta",	
              "_design/note",	
              "_design/rule",	
              "_design/taxCalendar",	
              "_design/taxRates",	
              "_design/taxRelations",
              "_design/user"
            ]
          }
        }
      ]
    }

    if (account.license.modules.includes('dac6')) {
      selector['$or'].push(
        {
          'type': 'dac6'
        })
    }

    return selector
  }
  delete masterToAccountReplicatorDoc.doc_ids
  masterToAccountReplicatorDoc.selector = createReplicationSelector()
  
  // Update replication
  yield call(fetchHelper, `${baseUrl}/_replicator/${masterToAccountReplicatorDoc._id}`, {
    method: 'PUT',
    body: masterToAccountReplicatorDoc
  })  

  // Update account document in admin. In order for these changes to be reflected in admin you need to do a publish.
  yield call(fetchHelper, `${baseUrl}/admin/${account._id}`, { method: 'PUT', body: account })

  // Update all users
  const users = yield select(getAccountUsers(account._id))
  for (let user of users) {
    yield put(putUser(user))
  }
}

const putNewAccount = function*(baseUrl, username, password, account){
  // Store document in master (we'll sync it to admin later)
  yield call(fetchHelper, `${baseUrl}/master/${account._id}`, { method: 'PUT', body: account })

  // Create db
  yield call(fetchHelper, `${baseUrl}/${account.url}`, { method: 'PUT' })

  // Create security document
  const security = {
    method: 'PUT',
    body: {
      'admins': {
        'names': [],
        'roles': ['_admin']
      },
      'members': {
        'names': [],
        'roles': ['_admin']
      }
    }
  }
  yield call(fetchHelper, `${baseUrl}/${account.url}/_security`, security)

  // Create replication document (master -> account db)
  const couchUrlWithCredentials = baseUrl.replace(/\/\//, `//${username}:${password}@`)
  const masterToAccountReplicatorDoc = {
          method: 'PUT',
          body: {
            _id: `masterTo_${account._id}`,
            doc_ids: [`${account._id}`],
            'roles': [
              '_admin',
              '_reader',
              '_writer'
            ],
            source: `${couchUrlWithCredentials}/master`,
            target: `${couchUrlWithCredentials}/${account.url}`,
            continuous: true,
            create_target: false
          }
        }
  yield call(fetchHelper, `${baseUrl}/_replicator/${masterToAccountReplicatorDoc.body._id}`, masterToAccountReplicatorDoc)  

  // Lastly sync the account once to admin
  const masterToAdminReplicatorDoc = {
    method: 'PUT',
    body: {
      _id: `masterTo_admin_${account._id}`,
      doc_ids: [`${account._id}`],
      'roles': [
        '_admin',
        '_reader',
        '_writer'
      ],
      source: `${couchUrlWithCredentials}/master`,
      target: `${couchUrlWithCredentials}/admin`,
      continuous: false,
      create_target: false
    }
  }
  yield call(fetchHelper, `${baseUrl}/_replicator/${masterToAdminReplicatorDoc.body._id}`, masterToAdminReplicatorDoc)  
}


export const sagas = {
  [PUT_ACCOUNT]: progressSaga((action) => [PUT_ACCOUNT, action.payload.draft._id],
    function* (local, action) {
      const { draft: account, username, password } = action.payload
      const { couchUrl: baseUrl } =
        yield call([local, local.get], config.localLoginDocId)

      // Check document type
      if (account.type !== 'account') {
        yield Promise.reject(
          { name: 'MissingTypeError', reason: `Type is missing or not valid` })
      }

      // Test admin credentials      
      yield delay(3000)
      const { session, timeout } = yield race({
        session: call((url, options) =>
          fetchHelper(url, options).then(undefined, (value) => value),
          `${baseUrl}/_session`,
          {
            body: { name: username, password },
            method: 'POST',
            credentials: 'include'
          }),
        timeout: delay(20000)
      })

      if (timeout) {
        throw timeoutError('Operation timed out!')
      }
      else if (!session.ok || !session.roles.includes('_admin')) {
        throw invalidAdminCredentials()
      }
            
      if (!account._rev) {
        yield call(putNewAccount, baseUrl, username, password, account)        
      }
      else {        
        yield call(updateAccount, baseUrl, account)
      }      
    }),
  [DELETE_ACCOUNT]: progressSaga((action) => [DELETE_ACCOUNT, action.payload.document._id],
    function* (local, action) {
      const { document } = action.payload

      const { couchUrl: baseUrl } =
        yield call([local, local.get], config.localLoginDocId)

      const tryDelete = function* (path) {
        try {
          const { timeout } = yield race({
            response: call(fetchHelper, path, { method: 'DELETE' }),
            timeout: delay(5000)
          })
          if (timeout) {
            console.warn(`Cannot delete document ${path} `)
          }
        } catch (ex) {
          if (ex.status !== 404) {
            throw e
          }
          console.warn(`Missing document ${path} `)
        }
      }

      // Delete replication
      try {
        const repId = `masterTo_${document._id} `
        const repDocument = yield call(fetchHelper, `${baseUrl} /_replicator/${repId} `)

        // yield call(`${ baseUrl } /_replicator/${ repId }?rev = ${ repDocument._rev } `)
        // const { timeout } = yield race({
        //   response: call(fetchHelper, `${ baseUrl } /_replicator/${ repId }?rev = ${ repDocument._rev } `, { method: 'DELETE' }),
        //   timeout: delay(5000)
        // })
        // if (timeout) {
        //   console.warn('Missing replication document', new Error())
        // }

        yield tryDelete(`${baseUrl} /_replicator/${repId}?rev=${repDocument._rev} `)

      } catch (ex) {
        if (ex.status !== 404) {
          throw e
        }
        console.warn("Missing replication document")
      }

      // Delete users      
      const userDocuments = yield select(getAccountUsers(document._id))
      for (const userDocument of userDocuments) {
        yield put(deleteUser(document._id, userDocument))
      }

      // Delete database
      yield tryDelete(`${baseUrl} /${document.url}`)

      // Delete account document
      yield tryDelete(`${baseUrl}/master/${document._id}?rev=${document._rev}`)
      yield tryDelete(`${baseUrl}/admin/${document._id}?rev=${document._rev}`)
    })
}

export default function* (db) {
  yield takeEvery(DELETE_ACCOUNT, sagas[DELETE_ACCOUNT], db)
  yield takeEvery(PUT_ACCOUNT, sagas[PUT_ACCOUNT], db)
}
