import React from 'react'

import { BOLD_MARK, ALIGN_MARK, UNDERLINE_MARK, ITALIC_MARK, COLOUR_MARK } from './markTypes'

const alignMarkStyle = {
  center: {
    textAlign: 'center',
    display: 'inline-block',
    width: '100%'
  },
  left: {
    textAlign: 'left',
    display: 'inline-block',
    width: '100%'
  },
  right: {
    textAlign: 'right',
    display: 'inline-block',
    width: '100%'
  }
}

const colourMarkStyle = {
  red: { color: 'rgb(255, 0, 0)' },
  orange: { color: 'rgb(255, 127, 0)' },
  yellow: { color: 'rgb(224, 224, 0)' },
  green: { color: 'rgb(0, 180, 0)' },
  blue: { color: 'rgb(0, 0, 255)' },
  indigo: { color: 'rgb(75, 0, 130)' },
  violet: { color: 'violet' },
  teal: { color: 'teal' }
}

export default function renderMark(mark, attributes, children) {
  switch (mark.type) {
    case BOLD_MARK:
      return <span style={{ fontWeight: 'bold' }} {...attributes}>{children}</span>
    case ALIGN_MARK:
      return <span style={alignMarkStyle[mark.data.get('align')]} {...attributes}>{children}</span>
    case UNDERLINE_MARK:
      return <span style={{ textDecoration: 'underline' }} {...attributes}>{children}</span>
    case ITALIC_MARK:
      return <span style={{ fontStyle: 'italic' }} {...attributes}>{children}</span>
    case COLOUR_MARK:
      return <span style={colourMarkStyle[mark.data.get('colour')]} {...attributes}>{children}</span>
  }
}