import React from 'react'

import styles from './DustbinButton.scss'

export default class DustbinButton extends React.PureComponent {
  render() {
    const { discard } = this.props
    return (
      <div title='Discard changes'
        className={styles.discardButton}
        onClick={discard}
      />
    )
  }
}