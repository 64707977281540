import cloneDeep from 'clone-deep'
import uuid from 'uuid/v4'
import { produce } from "immer"

const prefix = 'tree'
export const RESET_TREE = `${prefix}/reset`
export const TOGGLE_NODE = `${prefix}/toggle_node`
export const RENAME_NODE = `${prefix}/rename_node`
export const MOVE_NODE = `${prefix}/move_node`
export const REMOVE_NODE = `${prefix}/remove_node`
export const INSERT_NODE = `${prefix}/insert_node`
export const ADD_NODE = `${prefix}/add_node`

export function resetTree(tree) {
    return {
        type: RESET_TREE,
        payload: { tree }
    }
}


export function toggleNode(id) {
    return {
        type: TOGGLE_NODE,
        payload: { id }
    }
}

export function renameNode(id, name) {
    return {
        type: RENAME_NODE,
        payload: { id, name }
    }
}

export function moveNode(id, position) {
    return {
        type: MOVE_NODE,
        payload: { id, position }
    }
}

export function removeNode(id) {
    return {
        type: REMOVE_NODE,
        payload: { id }
    }
}

function insertNode(node, position) {
    return {
        type: INSERT_NODE,
        payload: { node, position }
    }
}

export function addNode(data, position) {
    return {
        type: ADD_NODE,
        payload: { data, position }
    }
}

const actions = {
    [TOGGLE_NODE]: (draft, action) => {
        const { id } = action.payload
        const { node } = getNodeWithParent(draft.nodes, id)
        node.expanded = !node.expanded
    },
    [RENAME_NODE]: (draft, action) => {
        const { id, name } = action.payload
        const { node } = getNodeWithParent(draft.nodes, id)
        node.name = name
        node.data.name = name
    },
    [MOVE_NODE]: (draft, action) => {
        const { id, position, position: { parentId } } = action.payload
        const { node, parent = null } = getNodeWithParent(draft.nodes, id)
        const { node: target} = getNodeWithParent(draft.nodes, parentId)
        if (node !== target && !(target && isParent(draft.nodes, node, target))) {
            actions[REMOVE_NODE](draft, removeNode(id))            
            actions[INSERT_NODE](draft, insertNode(node, position))
        }
    },
    [REMOVE_NODE]: (draft, action) => {
        const { id } = action.payload
        const { node, parent = null } = getNodeWithParent(draft.nodes, id)
        const siblings = parent ? parent.children : draft.nodes
        const siblingIndex = siblings.findIndex((sibling) => sibling.id === node.id)
        siblings.splice(siblingIndex, 1)
    },
    [INSERT_NODE]: (draft, action) => {
        const { node, position: { parentId, siblingId } } = action.payload
        const { node: parent = null } = getNodeWithParent(draft.nodes, parentId)
        const siblings = parent ? parent.children : draft.nodes
        const siblingIndex = siblingId ? siblings.findIndex((sibling) => sibling.id === siblingId) + 1 : 0
        siblings.splice(siblingIndex, 0, node)
    },
    [ADD_NODE]: (draft, action) => {
        const { data, position } = action.payload
        const node = createNode(data)
        actions[INSERT_NODE](draft, insertNode(node, position))
    },
    [RESET_TREE]: (draft, action) => {
        const { tree } = action.payload
        return tree
    }
}

const reducer = produce((draft, action) => {
    if (actions[action.type]) {
        return actions[action.type](draft, action)
    }
})

export default reducer

const getNodeWithParent = (nodes, id, parent = null) => {    
    let i = 0
    let node;
    while (i < nodes.length) {
        node = nodes[i]
        if (node.id === id) {           
            return { node, parent }
        }
        else if (node.children) {
            const result = getNodeWithParent(node.children, id, node)
            if (result && result.node) {
                return result
            }
        }
        i++
    }
    return {}
}

const getParents = (nodes, child, parents = []) => {
    let i = 0
    let node
    while (i < nodes.length) {
        node = nodes[i]
        if (node.id === child.id) {
            return parents
        }
        else if (node.children) {
            const result = getParents(node.children, child, [...parents, node])
            if (result && result.length)
                return parents
        }
        i++
    }
    return []
}

const isParent = (nodes, parent, child) => {
    const parents = getParents(nodes, child)
    return parents && parents.includes(parent)
}


export const createTree = (data) => {
    const nodes = data.map((node) => createNode(node, null))
    return {
        nodes
    }
}

const createNode = (data) => {
    const dataClone = cloneDeep(data)
    if (dataClone.children) {
        delete dataClone.children // Only keep children directly on the node
    }
    const node = {
        id: uuid(),
        name: dataClone.name,
        expanded: false,
        data: dataClone,
        children: []
    }
    if (data.children) {
        node.children = data.children.map((child) => createNode(child, node))
    }

    return node
}

