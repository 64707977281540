import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { Route } from 'react-router-dom'

import SubHeader from '../subHeader/SubHeader.jsx'
import SubHeaderLink from '../subHeader/SubHeaderLink.jsx'
import Commentary from './commentary/Commentary.jsx'
import styles from './TaxGuide.scss'

class TaxGuide extends React.Component {
  render() {
    return <div className={styles.container}>
      <Row noGutters={true}>
        <Col>
          <SubHeader>
            <SubHeaderLink to='/tax-guide/commentary'>COMMENTARY</SubHeaderLink>
            <SubHeaderLink to='/tax-guide/dac6'>DAC-6</SubHeaderLink>
          </SubHeader>
        </Col>
      </Row>
      <Row noGutters={true}>
        <Col>
          <Route path="/tax-guide/commentary"
            render={() => <Commentary /> } />
        </Col>
      </Row>
    </div>
  }
}

export default TaxGuide
