const prefix = 'statistics'
export const FETCH_STATISTICS = `${prefix}/fetch_statistics`
export const GET_STATISTICS = `${prefix}/get_statistics`
export const SET_STATISTICS = `${prefix}/set_statistics`

export function fetchStatistics(productKey, accountId) {
  return {
    type: FETCH_STATISTICS,
    payload: { productKey, accountId }
  }
}

// putStatistics

export function setStatistics(accountId, statistics) {
  return {
    type: SET_STATISTICS,
    payload: { accountId, statistics }
  }
}