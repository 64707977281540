// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".src-components-publish-Publish__container--307bd{padding-top:1em}.src-components-publish-Publish__publishButton--26mgc{width:6em}\n", "",{"version":3,"sources":["C:/Gitlab-Runner/builds/josiD6fY/0/developers/comtax-admin/src/components/publish/Publish.scss"],"names":[],"mappings":"AAAA,kDACI,eAAgB,CACnB,sDAGG,SAAU","file":"Publish.scss","sourcesContent":[".container {\r\n    padding-top: 1em;\r\n}\r\n\r\n.publishButton {\r\n    width: 6em;\r\n}"]}]);
// Exports
exports.locals = {
	"container": "src-components-publish-Publish__container--307bd",
	"publishButton": "src-components-publish-Publish__publishButton--26mgc"
};
module.exports = exports;
