import { setEventTransfer } from 'slate-react'
import { Block } from 'slate'
import renderBlock from './renderBlock'
import { TABLE_CELL_BLOCK } from './blockTypes'

export default function TablePlugin(options) {
  /**
   * On backspace, do nothing if at the start of a table cell.
   *
   * @param {Event} event
   * @param {Editor} editor
   */
  const onBackspace = (event, editor, next) => {
    const { value } = editor
    const { selection } = value
    if (selection.start.offset !== 0) {
      return next()
    }
    event.preventDefault()
  }

  /**
   * On delete, do nothing if at the end of a table cell.
   *
   * @param {Event} event
   * @param {Editor} editor
   */

  const onDelete = (event, editor, next) => {
    const { value } = editor
    const { selection } = value
    if (selection.end.offset !== value.startText.text.length) {
      return next()
    }
    event.preventDefault()
  }

  /**
   * On return, do nothing if inside a table cell.
   *
   * @param {Event} event
   * @param {Editor} editor
   */

  const onEnter = (event, editor, next) => {
    event.preventDefault()
    const { value } = editor
    const { document, selection } = value
    const { start, end } = selection

    const endNode = document.getNode(end.key)
    const cellBlock = document.getClosestBlock(endNode.key) // Cell
    const rowBlock = document.getParent(cellBlock.key) // Row
    const tableBlock = document.getParent(rowBlock.key) // Table
    const nextSibling = document.getNextSibling(tableBlock.key) // Sibling
    if (!nextSibling) {
      const parentBlock = document.getParent(tableBlock.key)
      const path = document.getPath(tableBlock.key)
      const index = path.get(0)
      editor.insertNodeByKey(parentBlock.key, index + 1, Block.create({ type: 'paragraph' }))
    }
  }

  return {
    renderBlock(props, editor, next) {
      const { attributes, children, node } = props
      return renderBlock(node, attributes, children) || next()
    },
    onKeyDown(event, editor, next) {
      const { value } = editor
      const { document, selection } = value
      const { start, isCollapsed } = selection
      const startNode = document.getDescendant(start.key)

      if (isCollapsed && start.isAtStartOfNode(startNode)) {
        const previous = document.getPreviousText(startNode.key)
        if (!previous) {
          return next()
        }
        const prevBlock = document.getClosestBlock(previous.key)
        if (prevBlock.type === TABLE_CELL_BLOCK) {
          if (['Backspace', 'Delete', 'Enter'].includes(event.key)) {
            event.preventDefault()
          } else {
            return next()
          }
        }
      }
      if (value.startBlock.type !== TABLE_CELL_BLOCK) {
        return next()
      }      
      switch (event.key) {
        case 'Backspace':
          return onBackspace(event, editor, next)
        case 'Delete':
          return onDelete(event, editor, next)
        case 'Enter':
          return onEnter(event, editor, next)
        default:
          return next()
      }
    },
    onCopy(event, editor, next) {
      const { value } = editor
      const { startNode } = value
      setEventTransfer(event, 'text', startNode)
    },
    onPaste(event, editor, next) {
      const { value } = editor
      const { startNode } = value
      setEventTransfer(event, 'text', startNode)
      next()
    },
    onDrop(event, editor, next) {
      return false
    }
  }
}
