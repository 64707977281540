import React from 'react'
import { withRouter, Route } from 'react-router-dom'
import { connect } from 'react-redux'

import { getMissingForCountry, getDocuments } from '../../../state/documents/documentReducer'
import { newCommentary } from '../../../state/documents/documentFactoryActions'
import DocumentFactory from '../DocumentFactory.jsx'
import DocumentEditor from '../../documentEditor/DocumentEditor.jsx'

import { Row, Col } from 'react-bootstrap'
import styles from './Commentary.scss'

const getMissingCommentaries = getMissingForCountry('commentary')
const getCommentaries = getDocuments('commentary')
const getCountries = getDocuments('country')

const mapStateToProps = (state, props) => {
  return {
    missingCommentaries: getMissingCommentaries(state),
    commentaries: getCommentaries(state),
    countries: getCountries(state)
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    newCommentary: (country) => dispatch(newCommentary(country))
  }
}

export class Commentary extends React.Component {
  render() {
    const {
      missingCommentaries,
      commentaries,
      countries,
      newCommentary
    } = this.props
    return <div>
      <Row>
        <Col lg={{ span: 2 }}>
          <Route path="/tax-guide/commentary/:id?" render={({ match }) => {
            return <DocumentFactory documentId={match.params.id} documents={commentaries} missingDocuments={missingCommentaries}
              newDocument={(countryId) => {
                const country = countries.find((country) => country._id === countryId)
                return newCommentary(country)
              }} />
          }} />
        </Col>
      </Row>
      <Row>
        <Col lg={{ span: 12 }}>
          <div className={styles.container}>
            <Route path="/tax-guide/commentary/:id" render={({ match }) => <DocumentEditor documentId={match.params.id} />} />
          </div>
        </Col>
      </Row>
    </div>
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Commentary))
