import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './ToggleButton.scss'

export default class ToggleButton extends React.Component {
  render() {
    const { active, icon, toggle } = this.props
    return (
      <span className={classNames(styles.toggleButton,
        { [styles.active]: active }, icon)}
        onMouseDown={toggle}
        title={this.props.label}>
      </span>
    )
  }
}

ToggleButton.propTypes = {
  label: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  icon: PropTypes.string
}
