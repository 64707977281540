const config = {}

const headers = new Headers()
headers.append('pragma', 'no-cache')
headers.append('cache-control', 'no-cache')

fetch('config.json', { headers })
  .then((response) => response.json())
  .then((json) => {
    Object.assign(config, json)
  })
  .catch((e) => {
    throw e
  })

export default config
