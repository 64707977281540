import { Mark } from 'slate'
import { ALIGN_MARK } from './markTypes'

export default function TextAlignMark(align) {
  return Mark.create({
    type: ALIGN_MARK,
    data: {
      align
    }
  })
}
