import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withRouter } from 'react-router-dom'

import styles from './SubHeaderLink.scss'

export class SubHeaderLink extends React.Component {
  render() {
    const { to, icon, enabled = true, history } = this.props
    const active = history.location.pathname.includes(to)
    return <div onClick={(e) => {
      e.preventDefault()
      if (enabled) {
        history.push(to)
      }
    }} className={
      classNames(styles.container,
        {
          [styles.disabled]: !enabled,
          [styles.active]: active
        })}>
      {icon &&
        <div className={classNames(styles.icon, icon)} />
      }
      <div className={styles.label}>{this.props.children}</div>
    </div>
  }
}

SubHeaderLink.propTypes = {
  to: PropTypes.string.isRequired,
  enabled: PropTypes.bool,
  children: PropTypes.string.isRequired,
  icon: PropTypes.string,
  history: PropTypes.object
}

export default withRouter(SubHeaderLink)
