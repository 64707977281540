import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import { hasChanges } from '../../utils/documentUtils'
import { getDocumentById } from '../../state/documents/documentReducer'
import styles from './DocumentFactory.scss'

const mapStateToProps = (state, props) => {
  return {
    disabled: props.documentId && hasChanges(getDocumentById(props.documentId)(state))
  }
}

const mapDispatchToProps = (dispath, props) => {
  return {
    push: (url) => props.history.push(url)
  }
}

const makePath = (doc) => `/tax-guide/${doc.type}/${doc._id}`
const isPath = (path) => path.startsWith('/tax-guide')

class DocumentFactory extends React.Component {
  constructor() {
    super()
    this.state = {
      selected: ''
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { location } = props
    if (state.selected !== location.pathname) {
      return {
        selected: location.pathname.split('/').slice(0, 4).join('/')
      }
    }
    return state
  }

  handleChange(value) {
    if (isPath(value)) {
      this.props.push(value)
      this.setState({
        selected: value
      })
    } else if (value) {
      const { payload: { document: newDocument } } = this.props.newDocument(value)
      const path = makePath(newDocument)
      this.props.push(path)
      this.setState({
        selected: path
      })
    }
  }

  render() {
    const { selected } = this.state
    const { documents, missingDocuments, disabled } = this.props
    return <div title={disabled ? 'You need to save or discard your changes before switching document' :
      '* means a new document will be created'}>
      <select disabled={disabled} className={styles.documentFactory}
        onChange={(e) => this.handleChange(e.currentTarget.value)}
        value={selected} >
        <option></option>
        {
          documents
            .concat(missingDocuments)
            .sort((lhs, rhs) => lhs.name.localeCompare(rhs.name))
            .map((doc) =>
              doc._id ? <option key={doc._id} value={makePath(doc)}>{doc.name}</option> :
                <option key={doc.name} value={doc.value}>*{doc.name}</option>
            )
        }
      </select>
    </div>
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DocumentFactory))
