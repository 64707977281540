import React from 'react'
import { connect } from 'react-redux'
import { Route, withRouter } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap'
import cloneDeep from 'clone-deep'

import Tagging from '../tagging/Tagging.jsx'
import { createDraft, setDraft, discardDraft, putDocument } from '../../state/documents/documentActions'
import { findSection, setSection, hasChanges, hasStructuralChanges } from '../../utils/documentUtils'
import { getDocumentById } from '../../state/documents/documentReducer'
import StructureEditor from './structureEditor/StructureEditor.jsx'
import TextEditor from './textEditor/TextEditor.jsx'
import styles from './DocumentEditor.scss'

const mapStateToProps = (state, props) => {
  const getDocument = getDocumentById(props.documentId)
  return {
    document: getDocument(state)
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    createDraft: (doc) => dispatch(createDraft(doc)),
    setDraft: (draft) => dispatch(setDraft(draft)),
    discardDraft: (draft) => dispatch(discardDraft(draft)),
    saveDocument: (doc) => dispatch(putDocument(doc, 2000))
  }
}

class DocumentEditor extends React.Component {
  constructor() {
    super()
    this.state = {
      sectionId: null
    }
  }

  componentDidMount() {
    if (!this.props.document._draft) {
      this.props.createDraft(this.props.document)
    }
  }

  componentDidUpdate(prevProps) {
    if (!this.props.document._draft) {
      this.props.createDraft(this.props.document)
    }
  }

  tagSection(section, tags) {
    const taggedSection = Object.assign({}, section, { tags })
    const draft = this.props.document._draft
    setDraft(setSection(draft, taggedSection))
  }

  render() {
    const { setDraft, discardDraft, saveDocument, document } = this.props
    const { sectionId } = this.state
    const isStructureChanged = hasStructuralChanges(document)
    const isChanged = hasChanges(document)    
    const section = findSection(document, sectionId)
    return <div>
      <Row>
        <Col lg={{ offset: 1, span: 10 }}>
          <h2 className={styles.header}>{document.name}</h2>
        </Col>
      </Row>
      <Row>
        <Col lg={{ span: 12 }}>
          <div className={styles.structure}>
            {document._draft && <StructureEditor
              onChange={setDraft}
              discardChanges={discardDraft}
              saveChanges={() => saveDocument(document._draft)}
              hasChanges={isStructureChanged}
              progress={document._progress || {}}
              document={document._draft || {}}
              disabled={!isStructureChanged && isChanged}
              navigate={(sectionId) => {
                this.setState({ sectionId })
              }}
            />
            }
          </div>
          <div className={styles.textEditor}>
            {section &&
              <div><h3 className={styles.subHeader}>{section.name}</h3>
                <TextEditor
                  readonly={isStructureChanged}
                  setDraft={setDraft}
                  discardChanges={discardDraft}
                  document={document}
                  sectionId={sectionId}
                  hasChanges={isChanged}
                  saveChanges={() => saveDocument(document._draft)}
                  readonly={isStructureChanged} />
                <div className={styles.tags}>
                  <Tagging tags={document._draft ? findSection(document._draft, section.sectionId).tags : {}} onChange={(tags) => {
                    this.tagSection(section, tags)
                    this.forceUpdate()
                  }} />
                </div>
              </div>
            }
          </div>
        </Col>
      </Row>
    </div>
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentEditor)
