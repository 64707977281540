// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".src-components-documentEditor-textEditor-HeaderSelect__container--2sczg{box-sizing:border-box;display:inline-block;height:20px;line-height:20px;font-weight:bold;font-size:20px;vertical-align:top}.src-components-documentEditor-textEditor-HeaderSelect__label--3FV5i{box-sizing:border-box;display:inline-block;height:20px;line-height:20px;font-weight:bold;font-size:24px;vertical-align:top;position:relative}.src-components-documentEditor-textEditor-HeaderSelect__select--3FVyJ{height:20px;font-size:13px;font-weight:bold;line-height:20px}.src-components-documentEditor-textEditor-HeaderSelect__select--3FVyJ option{padding:0}\n", "",{"version":3,"sources":["C:/Gitlab-Runner/builds/josiD6fY/0/developers/comtax-admin/src/components/documentEditor/textEditor/HeaderSelect.scss"],"names":[],"mappings":"AAEA,yEACE,qBAAsB,CACtB,oBAAqB,CACrB,WAAY,CACZ,gBAAiB,CACjB,gBAAiB,CACjB,cAAe,CACf,kBAAmB,CACpB,qEAGC,qBAAsB,CACtB,oBAAqB,CACrB,WAAY,CACZ,gBAAiB,CACjB,gBAAiB,CACjB,cAAe,CACf,kBAAmB,CACnB,iBAAkB,CACnB,sEAGC,WAAY,CACZ,cAAe,CACf,gBAAiB,CACjB,gBAAiB,CAJnB,6EAMI,SAAU","file":"HeaderSelect.scss","sourcesContent":["@import '../../_base.scss';\r\n\r\n.container {\r\n  box-sizing: border-box;\r\n  display: inline-block;\r\n  height: 20px;\r\n  line-height: 20px;\r\n  font-weight: bold;\r\n  font-size: 20px;\r\n  vertical-align: top;\r\n}\r\n\r\n.label {\r\n  box-sizing: border-box;\r\n  display: inline-block;\r\n  height: 20px;\r\n  line-height: 20px;\r\n  font-weight: bold;\r\n  font-size: 24px;\r\n  vertical-align: top;\r\n  position: relative;\r\n}\r\n\r\n.select {\r\n  height: 20px;\r\n  font-size: 13px;\r\n  font-weight: bold;\r\n  line-height: 20px;\r\n  option {\r\n    padding: 0;\r\n  }\r\n}"]}]);
// Exports
exports.locals = {
	"container": "src-components-documentEditor-textEditor-HeaderSelect__container--2sczg",
	"label": "src-components-documentEditor-textEditor-HeaderSelect__label--3FV5i",
	"select": "src-components-documentEditor-textEditor-HeaderSelect__select--3FVyJ"
};
module.exports = exports;
