import React from 'react'
import Html from 'slate-html-serializer'
import { Block, Mark } from 'slate'

import renderBlock from './renderBlock'
import renderTableBlock from './plugins/table/renderBlock'
import renderRTFMark from './plugins/rtf/renderMark'

const rule = (serialize, deserialize = () => { throw new Error() }) => {
  return { serialize, deserialize }
}

const isBlock = (node) => node instanceof Block
const isMark = (node) => node instanceof Mark

const paragraphRule = rule((node, children) => {
  if (isBlock(node) && node.type === 'paragraph') {
    return <p>{children}</p>
  }
})

const blockRule = rule((node, children) => {
  if (isBlock(node)) {
    return renderBlock(node, {}, children)
  }
})

const tableBlockRule = rule((node, children) => {
  if (isBlock(node)) {
    return renderTableBlock(node, {}, children)
  }
})

const rtfMarkRule = rule((node, children) => {
  if (isMark(node)) {
    return renderRTFMark(node, {}, children)
  }
})

const rules = [
  rtfMarkRule,
  paragraphRule,
  blockRule,
  tableBlockRule
]

export default function (value) {
  const html = new Html({
    rules,
    defaultBlock: { type: 'paragraph' },
    parseHtml: () => { throw new Error() }
  })
  return html.serialize(value)
}
