// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".src-components-statistics-AccountStatistics__spacer--217dQ{margin-bottom:2rem}.src-components-statistics-AccountStatistics__mainHeading--3BVXz{font-size:2rem}.src-components-statistics-AccountStatistics__subHeading--1T_SP{font-size:1.3rem}\n", "",{"version":3,"sources":["C:/Gitlab-Runner/builds/josiD6fY/0/developers/comtax-admin/src/components/statistics/AccountStatistics.scss"],"names":[],"mappings":"AAAA,4DACE,kBAAmB,CACpB,iEAGC,cAAe,CAChB,gEAGC,gBAAiB","file":"AccountStatistics.scss","sourcesContent":[".spacer {\r\n  margin-bottom: 2rem;\r\n}\r\n\r\n.mainHeading {\r\n  font-size: 2rem;\r\n}\r\n\r\n.subHeading {\r\n  font-size: 1.3rem;\r\n}"]}]);
// Exports
exports.locals = {
	"spacer": "src-components-statistics-AccountStatistics__spacer--217dQ",
	"mainHeading": "src-components-statistics-AccountStatistics__mainHeading--3BVXz",
	"subHeading": "src-components-statistics-AccountStatistics__subHeading--1T_SP"
};
module.exports = exports;
