// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".src-components-documentEditor-structureEditor-StructureEditor__lock--1uW2s{display:inline-block}\n", "",{"version":3,"sources":["C:/Gitlab-Runner/builds/josiD6fY/0/developers/comtax-admin/src/components/documentEditor/structureEditor/StructureEditor.scss"],"names":[],"mappings":"AAEA,4EACE,oBAAqB","file":"StructureEditor.scss","sourcesContent":["@import '../../_base.scss';\r\n\r\n.lock {\r\n  display: inline-block;\r\n}\r\n"]}]);
// Exports
exports.locals = {
	"lock": "src-components-documentEditor-structureEditor-StructureEditor__lock--1uW2s"
};
module.exports = exports;
